import { useEffect, useState } from 'react';
import { MapContainer, useMap, Marker, TileLayer, Popup, CircleMarker, Polyline, SVGOverlay } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { get_intervention_ambulance_geo_position } from '../../services/management/intervention_ambulance.service';
import { AmbulanceSvgIcon } from './AmbulanceSvgIcon';


// Configuration de l'icône par défaut de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const circleBaseProps = {
  pathOptions: { fillColor: 'red' },
  radius: 10
}

const geoPositionStrToArray = (position) => {
  let [longitude, latitude] = position.split(":")
  return [parseFloat(latitude), parseFloat(longitude)]
}

const AmbulanceTrackingMap = ({ interventionAmbulanceId, geoPosition, height = "h-screen" }) => {
  const [positions, setPositions] = useState([]);
  const [geoLocationSupported, setGeoLocationSupported] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGeoLocationSupported('geolocation' in navigator)
    setLoading(true);
    const initGeoPositions = async () => {
      try {
        const response = await get_intervention_ambulance_geo_position(interventionAmbulanceId);
        console.log("---positions ", response)
        setPositions(response.positions.map(geoPositionStrToArray))
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    initGeoPositions()
  }, []);

  useEffect(() => {
    if (!(geoLocationSupported && geoPosition)) return
    let newPosition = geoPositionStrToArray(geoPosition)
    console.log("---------------New geoPosition ", newPosition)
    setPositions((prevPositions) => [...prevPositions, newPosition]);
  }, [geoPosition, geoLocationSupported]);

  useEffect(() => {
    console.log("---------------Changee ", loading, positions, positions.length)
  }, [loading, positions]);

  if (loading || !positions.length) return <ProgressSpinner />

  return (
    <div className={`${height} `} style={{ border: "2px solid", maxHeight: "800px" }}>
      <MapContainer center={positions[positions.length - 1]} zoom={13} className="h-full">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={positions[0]}>
          <Popup>
            Point de départ de l'Ambulance.
          </Popup>
        </Marker>
        <Polyline
          pathOptions={{ color: 'red', dashArray: '4, 10' }}
          positions={positions.slice(1, positions.length)}
        />
        <Marker position={positions[positions.length - 1]} icon={AmbulanceSvgIcon}></Marker>
      </MapContainer>
    </div>
  );
};

export default AmbulanceTrackingMap;
