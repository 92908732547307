import { Icon } from '@iconify/react';

const CardStatOne = ({ icon, title, value, bg_color }) => {
    return (
        <div className="w-fit mt-1 sm:mt-0">
            <div className="flex items-center pr-3">
                <div className={`p-2 rounded-full bg-opacity-75 ${bg_color}`}>
                    <Icon icon={icon} className="text-white text-2xl" />
                </div>

                <div className="mx-2">
                    {/* <h4 className="text-2xl font-semibold text-gray-700"></h4> */}
                    <div className="text-gray-500 flex justify-center gap-x-2 items-center">
                        <p className="font-bold text-4xl text-primary">{value} </p>
                        <p className="w-max">{title}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardStatOne;
