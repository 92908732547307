import { NavLink } from "react-router-dom";
import BottomNavigation from "./BottomNavigation";
import { Icon } from '@iconify/react';
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";

const AdminSidebar = (props) => {

  const { user } = useContext(UserContext);
  const has_role = (required_roles) => {
    return user.groups.some(group => required_roles.includes(group.name));
  };

  return (
    <aside
      {...props}
      aria-label="Sidenav"
      id="drawer-navigation"
    >
      <div className="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
        <ul className="space-y-2 mb-12 menu-element">
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.ADMIN_DASHBOARD} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="ri:dashboard-fill" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
              <span className="ml-4">Tableau de bord</span>
            </NavLink>
          </li>
          <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Gestion des Opérations</p>
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.ADMIN_INTERVENTIONS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="ph:siren-fill" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
              <span className="ml-4">Interventions</span>
            </NavLink>
          </li>
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.SCHEDULED_INTERVENTION} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="healthicons:hospitalized" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" style={{fontSize: "25px"}} />
              <span className="ml-4">Interventions Programmées</span>
            </NavLink>
          </li>
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.MEDICAL_COVERS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="bx:plus-medical" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
              <span className="ml-4">Couvertures</span>
            </NavLink>
          </li>
          {/* <li className="my-4">
                        <NavLink to={INTERNAL_ROUTES.ADMIN_PATIENTS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                            <Icon icon="mdi:patient" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                            <span className="ml-4">Patients</span>
                        </NavLink>
                    </li> */}
          {has_role(['chief-supervisor', 'admin']) && (
            <li className="my-4">
              <NavLink to={INTERNAL_ROUTES.PLANIFICATION} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                <Icon icon="ion:calendar-sharp" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                <span className="ml-4">Planification</span>
              </NavLink>
            </li>
          )}

          {has_role(['chief-supervisor', 'admin']) && (
            <>
              {/* <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Statistiques</p>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.KPI_STATS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="carbon:summary-kpi" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">KPI Principaux</span>
                </NavLink>
              </li> */}
              {/* <li className="my-4">
                                <NavLink to={INTERNAL_ROUTES.INTERVENTIONS_STATS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                                    <Icon icon="solar:chart-bold" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                                    <span className="ml-4">Interventions</span>
                                </NavLink>
                            </li> */}
              {/* <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.GEOGRAPHICAL_STATS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="wpf:geo-fence" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Carte géographique</span>
                </NavLink>
              </li> */}
              {/* <li className="my-4">
                                <NavLink to={INTERNAL_ROUTES.TREND_STATS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                                    <Icon icon="icon-park-solid:trend-two" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                                    <span className="ml-4">Evolution et tendance</span>
                                </NavLink>
                            </li> */}
              {/* <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.PERFORMANCE_STATS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="gg:performance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Performance équipe</span>
                </NavLink>
              </li> */}
            </>

          )}


          {/* {has_role(['admin']) && (
            <li className="my-4">
              <NavLink to={INTERNAL_ROUTES.FINANCES} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                <Icon icon="healthicons:money-bag" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                <span className="ml-4">Finances</span>
              </NavLink>
            </li>
          )} */}

          {has_role(['admin']) && (
            <>
              <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Gestion des antennes</p>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.COMPANIES} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="mdi:office-building-marker-outline" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Antennes</span>
                </NavLink>
              </li>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.CLIENT_SERVICES} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="healthicons:money-bag" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Services fournis</span>
                </NavLink>
              </li>
            </>
          )}

          {has_role(['admin']) && (
            <>
              <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Centres partenaires</p>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.OTHERS_COMPANIES} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="mdi:office-building-marker-outline" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Centres partenaires</span>
                </NavLink>
              </li>
            </>
          )}

          {has_role(['admin']) && (
            <>
              <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Gestion du personnel</p>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.MEDICAL_STAFF} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="mdi:doctor" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Personnel Médical</span>
                </NavLink>
              </li>
            </>
          )}

          <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Dossiers patients</p>
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.MEDICAL_RECORD} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="mdi:doctor" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
              <span className="ml-4">Dossiers patients</span>
            </NavLink>
          </li>


          <p className="text-xs text-secondary px-0 mt-6 font-semibold hidden lg:block">Gestion des ressources</p>
          <li className="my-4">
            <NavLink to={INTERNAL_ROUTES.STOCK_MATERIALS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
              <Icon icon="solar:medical-kit-bold" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
              <span className="ml-4">Stock & Matériels</span>
            </NavLink>
          </li>
          {has_role(['admin', 'chief-supervisor']) && (
            <>
              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.AMBULANCES} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="bxs:ambulance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Ambulances</span>
                </NavLink>
              </li>
            </>
          )}

          {has_role(['admin']) && (
            <>
              <p className="text-xs text-secondary px-0 mt-4 font-semibold hidden lg:block">Administration</p>

              <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.SETTINGS} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="mdi:settings" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Paramètres</span>
                </NavLink>
              </li>
              {/* <li className="my-4">
                <NavLink to={INTERNAL_ROUTES.ADMIN_LIBRARY} className={({ isActive }) => isActive ? "custom-link-sidebar active-link group" : "custom-link-sidebar group"}>
                  <Icon icon="solar:library-bold" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
                  <span className="ml-4">Bibliothèque</span>
                </NavLink>
              </li> */}
            </>
          )}

        </ul>
      </div>

      <BottomNavigation />
    </aside>
  );

}

export default AdminSidebar;