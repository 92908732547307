export const INTERNAL_ROUTES = {
  LOGIN: '/',
  FORBIDDEN: '/app/forbidden',
  ADMIN_BACKOFFICE: '/app/administration',
  ADMIN_DASHBOARD: 'app/dashboard',
  STAFF_BACKOFFICE: '/app/personnel-medical',
  STAFF_DASHBOARD: 'app/dashboard',
  PATIENT_DASHBOARD: 'app/patient',

  INTERVENTIONS: 'app/interventions',

  ADMIN_INTERVENTIONS: 'app/interventions',
  ADMIN_INTERVENTION_FORM: 'app/interventions/formulaire-intervention',
  ADMIN_INTERVENTION_SUMMARY: 'app/interventions/vue-sommaire-intervention',
  ADMIN_INTERVENTION_MAP: 'app/interventions/suivi-intervention',

  PATIENTS: 'app/patients',
  PATIENTS_DETAIL: 'app/patients/:id',
  ADMIN_PATIENTS: 'app/patients',
  ADMIN_PATIENTS_DETAIL: 'app/patients/:id',

  PLANIFICATION: 'app/planification',

  KPI_STATS: 'app/statistiques/kpi-principaux',
  INTERVENTIONS_STATS: 'app/statistiques/interventions',
  GEOGRAPHICAL_STATS: 'app/statistiques/repartition-geographique',
  PERFORMANCE_STATS: 'app/statistiques/performance-equipe',
  TREND_STATS: 'app/statistiques/tendances-et-evolution',

  FINANCES: 'app/finances',

  TEAMS: 'app/equipes-de-permanence',
  ADMIN_TEAMS: 'app/equipes',

  INTERVENTION_FORM: 'app/interventions/formulaire-intervention',
  INTERVENTION_SUMMARY: 'app/interventions/vue-sommaire-intervention',
  INTERVENTION_MAP: 'app/interventions/suivi-intervention',

  TEAMS_DETAIL: 'app/equipes-de-permanence/:id',
  ADMIN_TEAMS_DETAIL: 'app/equipes/:id',

  COMPANIES: 'app/gestion-des-branches',
  COMPANIES_DETAIL: 'app/vue-detail-branche',

  MEDICAL_STAFF: 'app/users',
  MEDICAL_STAFF_DETAIL: 'app/vue-detaillee-personnel-medical',

  MEDICAL_RECORD: 'app/medical-record',
  MEDICAL_RECORD_DETAIL: 'app/medical-record',

  STOCK_MATERIALS: 'app/stock-et-materiel',
  STOCK_MATERIALS_DETAIL: 'app/stock-et-materiel/:id',

  AMBULANCES: 'app/ambulances',
  AMBULANCE_DETAIL: 'app/ambulances/detail-ambulance',
  AMBULANCES_TYPES: 'app/ambulances_type',
  AMBULANCES_QUALIFICATIONS: 'app/ambulances_qualifications',
  INTERVENTION_REASONS: 'app/intervention_reasons',

  LIBRARY: 'app/base-de-connaissances',
  ADMIN_LIBRARY: 'app/bibliotheque',

  NOTIFICATION: 'app/notifications',
  ADMIN_NOTIFICATION: 'app/notifications',

  SETTINGS: 'app/settings',

  MEDICAL_COVERS: 'app/medical-covers',
  MEDICAL_COVERS_DETAIL: 'app/medical-covers/detail',

  SCHEDULED_INTERVENTION: 'app/scheduled-interventions',

  OTHERS_COMPANIES: 'app/others-companies',
  SERVICES: 'app/services/:company_id',
  SERVICES_ROOMS: 'app/services_rooms/:service_id',
  SERVICES_ROOMS_BEDS: 'app/services_rooms_beds/:service_room_id',
  MONITORING_PARAMS: 'app/monitoring_params',
  MONITORING_PARAMS_CHOICE: 'app/monitoring_params_choices/:param_id',
  INTERVENTION_TEAM_MONITORING: 'app/interventions_team_monitoring/:intervention_team_id',
  CLIENT_SERVICES: 'app/client_services',
  CLIENT_SERVICES_PRICES: 'app/client_services_prices/:service_id',
  CLIENT_SERVICES_RATINGS: 'app/client_services_ratings/:service_id',
};
