import { Label } from "flowbite-react"
import { useMemo } from "react"

const FormField = (props) => {
  const { Component, componentProps, placeholder, style, error, ...otherProps } = props
  const componentStyle = useMemo(() => {
    let _style = {...style}
    if (error) _style.border = "1px solid red"
    return _style
  }, [style, error])


  return (
    <div className="mb-5 mx-5">
      <div className="mb-2 block pl-0 ml-0">
        <Label value={placeholder} />
      </div>
      <Component
        {...componentProps}
        {...otherProps}
        className="w-full"
        style={componentStyle}
      />
      {error?.length && error.map(msg => (
        <div className="flex"><span className="mb-2 block pl-0 ml-0 text-red text-xs">{msg}</span></div>
      ))}
    </div>
  )
}
export default FormField