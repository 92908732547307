
import InterventionCard from '../../components/cards/interventions/InterventionCard';
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import { Icon } from '@iconify/react';
import { Toast } from "primereact/toast";
import { create_intervention, get_user_interventions, getInterventionAmbulanceChief } from "../../services/interventions/intervention.service";
import Loading from "../../components/Utils/Loading";
import { differenceInMinutes, parseISO } from "date-fns";


function format_interventions(data) {
    const in_progress_statuses = ["not-started", "team-formed", "ambulance-on-going", "ambulance-returned", "ambulance-arrived"];
    const canceled_statuses = ["white-out", "canceled"];

    const in_progress_interventions = [];
    const other_interventions = [];

    let total = 0;
    let canceled = 0;
    let done = 0;
    let in_progress = 0;

    data.results.forEach((intervention) => {
        const { id, status, ended_at, duration, description } = intervention;

        let lead = getInterventionAmbulanceChief(intervention);

        const identifier = `#${String(id).padStart(4, "0")}`;
        const { return_at_base_datetime, location } = intervention.forms[intervention.forms.length - 1] || {};
        const transformed_intervention = {
            id,
            identifier,
            status,
            location,
            lead,
            description,
            ended_at,
            duration
        };

        if (in_progress_statuses.includes(status)) {
            in_progress_interventions.push(transformed_intervention);
            in_progress++;
        } else {
            if (canceled_statuses.includes(status)) {
                canceled++;
            } else {
                done++;
            }
            other_interventions.push(transformed_intervention);
        }
    });

    // Trier les autres interventions par date de création (décroissant)
    other_interventions.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    return {
        data: { in_progress: in_progress_interventions, others: other_interventions },
        stats: { total, canceled, done, in_progress },
    };
}

const InterventionList = () => {
    const { user } = useContext(UserContext);
    const [interventions, setInterventions] = useState({ in_progress: [], others: [] });
    // const [stats, setStats] = useState({total : 0, canceled : 0, done : 0, in_progress : 0});
    const [ready, setReady] = useState(false);

    const has_role = (required_roles) => {
        return user.groups.some(group => required_roles.includes(group.name));
    };

    const navigate = useNavigate();
    const toast = useRef(null);

    useEffect(() => {
        const get_staff_interventions = async () => {
            try {
                const data = await get_user_interventions();
                const formatted_data = format_interventions(data);
                setInterventions(formatted_data.data);
                // setStats(formatted_data.stats);
                setReady(true);
            } catch (e) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000 });
            }
        }

        get_staff_interventions();
    }, []);


    const handle_new_intervention = async () => {
        try {
            const data = await create_intervention();
            navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_FORM}/${data.id}`);

        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000 });
        }
    }
    const go_to_intervention_detail = async (id) => {
        navigate(`${INTERNAL_ROUTES.STAFF_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_SUMMARY}/${id}`);
    }

    if (!ready) {
        return (
            <>
                <Toast ref={toast} />
                <Loading />
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="flex flex-col my-4 px-4 lg:px-8 items-center justify-center">
                <div className='grid lg:grid-cols-4 gap-2 grid-cols-1'>
                    <button
                        onClick={() => navigate(-1)} className="flex justify-center gap-2 bg-gray-800 text-white px-4 py-2 items-center rounded"
                        style={{ width: "100px" }}
                    >
                        Retour
                        <Icon icon={'ph:arrow-left'} />
                    </button>
                    <div className="flex items-center gap-4">
                        <Icon icon="mdi:clock" className="text-gray-500 mr-2 text-xl" />
                        <p className="font-semibold text-lg xl:text-xl">En cours</p>
                    </div>
                    <div className="relative text-gray-600">
                        <input className="border border-gray-300 bg-white h-10 px-6 pr-4 rounded text-sm focus:outline-none" type="search" name="search" placeholder="Rechercher" />
                        <button type="submit" className="absolute left-0 -top-2 ml-1 mt-5 mr-4">
                            <Icon icon="flowbite:search-solid" className="text-gray-500 text-xl" />
                        </button>
                    </div>
                    {has_role(["arm", "medecin-regulator"]) && (
                        <button onClick={handle_new_intervention} className="flex justify-center gap-2 bg-secondary text-white px-4 py-2 items-center rounded">
                            Nouvelle Intervention
                            <Icon icon={'ph:plus-fill'} />
                        </button>
                    )}
                </div>

                <div className="grid lg:grid-cols-4 gap-4 grid-cols-1 py-5">
                    {interventions.in_progress.length > 0 ? (
                        interventions.in_progress.map((i) => (
                            <InterventionCard id={i.id} key={i.id} num={i.identifier} area={i.location} duration={i.duration} status={i.status} team_lead={i.lead} description={i.description} on_click={() => go_to_intervention_detail(i.id)} />
                        ))
                    ) :
                        (
                            <p className="text-center text-lg font-semibold text-gray-500">Aucune intervention en cours</p>
                        )}
                </div>


                <div className="flex flex-col my-4 px-4 lg:px-8 items-center justify-center">
                    <div className="flex flex-nowrap overflow-x-auto items-center justify-start px-4 md:px-8 py-4 w-full">
                        <h5><span className="font-semibold text-lg xl:text-xl">Les dernières interventions</span></h5>
                    </div>
                    <div className="grid lg:grid-cols-4 gap-4 grid-cols-1 py-5">
                        {interventions.others.length > 0 ? (
                            interventions.others.map((i) => (
                                <InterventionCard id={i.id} key={i.id} num={i.identifier} area={i.location} duration={i.duration} status={i.status} team_lead={i.lead} description={i.description} on_click={() => go_to_intervention_detail(i.id)} />
                            ))
                        ) :
                            (
                                <p className="text-center text-lg font-semibold text-gray-500">Aucune intervention sur les trois derniers jours</p>
                            )
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default InterventionList