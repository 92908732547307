import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const ServicesRoomsBeds = ({
  staticFilters = {},
  actions={ create: false, update: false, delete: false },
  routeActions={},
  crudListProps={}
}) => {

  const { service_room_id } = useParams()

  return (
    <CrudList
      type={crudListProps?.type || CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "services_rooms_beds",
        label: "lit",
        labelPlural: "lits",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        getSubTitle: (item) => item.location,
        imageField: null
      }}
      fields={[
        {
          name: "number",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Numéro",
          labelPlural: "Numéros",
        },
        {
          name: "status",
          type: CRUD_FIELD_TYPE.ENUM,
          choices: [
            {
              value: "free",
              label: "Disponible",
              severity: "success"
            },
            {
              value: "reserved",
              label: "Reservé",
              severity: "warning"
            },
            {
              value: "occupied",
              label: "Occupé",
              severity: "warning"
            },
            {
              value: "spoiled",
              label: "Non fonctionnel",
              severity: "error"
            },
          ],
          required: true,
          label: "status",
          labelPlural: "status",
        },
      ]}
      staticFilters={{service_room: service_room_id}}
      staticFormData={{service_room: service_room_id}}
      actions={actions}
      defaultPageSize={10}
      {...crudListProps}
    />
  )
}

export default ServicesRoomsBeds;
