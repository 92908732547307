import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Card } from 'primereact/card';
import FormModal from '../../../components/modals/FormModal';
import Heading from '../../../components/Utils/Heading';
import { Toast } from 'primereact/toast';
import DateFilter from '../../../components/forms/DateFilter';
import { Icon } from '@iconify/react';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const GeographicalCard = () => {
    const [mapData, setMapData] = useState([]);

    const [filter_start_date, setFilterStartDate] = useState(null);
    const [filter_end_date, setFilterEndDate] = useState(null);

    const [filter_visible, setFilterVisible] = useState(false);
    const [btn_loading, setBtnLoading] = useState(false);


    const toast = useRef(null)
    useEffect(() => {
        // Simuler l'appel API avec des données statiques
        setTimeout(() => {
            setMapData([
                { position: [48.8566, 2.3522], description: 'Intervention à Paris' },
                { position: [45.7640, 4.8357], description: 'Intervention à Lyon' },
                { position: [43.6045, 1.4442], description: 'Intervention à Toulouse' }
            ]);
        }, 1000);
    }, []);

    const get_period_stats = async (event) => {
        event.preventDefault();
        setBtnLoading(true);
        try {
            alert("En cours d'implémentation");
            const param = {
                start_date: filter_start_date,
                end_date: filter_end_date
            }
            setFilterVisible(false);
            setBtnLoading(false);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Statistiques récupérées avec succès.', life: 5000 });
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000 });
        }
    }

    const show_filter_modal = () => {
        setFilterVisible(true);
    };


    return (
        <>
            <Toast ref={toast} />
            <Heading title="Positions géographiques récurrentes" />
            <FormModal header={"Filtrer"} visible={filter_visible} setVisible={setFilterVisible} children={<DateFilter on_submit={get_period_stats} loading={btn_loading} set_start_date={setFilterStartDate} set_end_date={setFilterEndDate} type="positions géographiques récurrentes" />} />

            <div className="w-full flex justify-end items-center px-4 md:px-8">
                <button onClick={show_filter_modal} className="flex gap-2 justify-center items-center px-4 py-1.5 bg-black hover:bg-primary rounded text-white"><Icon icon={'fa:filter'} className="text-xl" /> <span>Filtrer</span></button>
            </div>

            <div className="flex justify-center px-4 my-8 items-center w-full">
                <div className="w-full">
                    <Card title="Répartition Géographique des Interventions">
                        <MapContainer center={[47.081, 2.398]} zoom={6} style={{ height: "400px", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {mapData.map((location, idx) => (
                                <Marker key={idx} position={location.position}>
                                    <Popup>{location.description}</Popup>
                                </Marker>
                            ))}
                        </MapContainer>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default GeographicalCard