
import { Calendar } from 'primereact/calendar';
import LoadingButton from '../../Utils/LoadingButton';

const UpdatePlanning = ({ on_submit, loading, dates, setDates }) => {

    return (
        <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
            <div className="flex justify-center mb-4">
                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} inline dateFormat="dd/mm/yy" />
            </div>

            <LoadingButton label="Valider" loading={loading} />
        </form>
    )
}


export default UpdatePlanning