import { useParams } from "react-router-dom";
import InterventionFormEditor from "./InterventionFormEditor"
import CrudList from "../../components/crud/list";
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from "../../components/crud/constants";
import { useState } from "react";


const InterventionManager = () => {

  const { intervention_id } = useParams();
  const [selectedForm, setSelectedForm] = useState(null)


  if (selectedForm) return (
    <div className="w-full">
      <InterventionFormEditor
        id={selectedForm.id}
        interventionId={intervention_id}
        onClose={() => setSelectedForm(null)}
      />
    </div>
  )

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "intervention_forms",
        label: "formulaire patient",
        labelPlural: "formulaires patient",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:ambulance",
        getTitle: (item) => item.patient_names,
        imageField: null
      }}
      fields={[
        {
          name: "patient_names",
          type: CRUD_FIELD_TYPE.STRING,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "status",
          type: CRUD_FIELD_TYPE.STRING,
          label: "",
          labelPlural: "",
        },
      ]}
      writeOnlyFields={["patient_names"]}
      staticFilters={{intervention: intervention_id}}
      staticFormData={{intervention: intervention_id}}
      actions={{ create: true, update: false, delete: true}}
      defaultPageSize={10}
      customActions={[
        {
          onClick: (item) => setSelectedForm(item),
          label: "Ouvrir"
        }
      ]}
      autoSelectFirst={true}
      onAutoSelectFirst={(item) => setSelectedForm(item)}
    />
  )
}

export default InterventionManager
