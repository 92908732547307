export const CRUD_FIELD_TYPE = {
    NUMBER: "number",
    STRING: "string",
    TEXT: "text",
    ENUM: "enum",
    ENUM_LIST: "enum-list",
    BOOLEAN: "boolean",
    DATE: "date",
    RELATED_FOREIGN_KEY: "related-foreign-key",
    RELATED_MANY_TO_MANY: "related-many-to-many",
}

export const CRUD_VIEW_TYPE = {
    TABLE: "table",
    CARD: "card",
}
