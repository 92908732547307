import { AMBULANCES_URL } from "../urls";
import axios from './../customAxios';


export const get_ambulances = async (limit, offset, othersParams = {}) => {
    try {
        const response = await axios.get(AMBULANCES_URL, {
            params: {
                limit: limit,
                offset: offset,
                ...othersParams
            }
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const retrieve_ambulance = async (id) => {
    try {
        const response = await axios.get(`${AMBULANCES_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const create_ambulance = async (data) => {
    try {
        const response = await axios.post(AMBULANCES_URL, {
            'identifier': data.identifier,
            'status': data.status,
            'qualifications': [data.qualifications],
            'description': data.description
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_ambulance = async (updated_data, id) => {
    try {
        const response = await axios.patch(`${AMBULANCES_URL}${id}/`, {
            'identifier': updated_data.identifier,
            'status': updated_data.status,
            'qualifications': [updated_data.qualifications],
            'description': updated_data.description
        });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const delete_ambulance = async (id) => {
    try {
        const response = await axios.delete(`${AMBULANCES_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
