import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { PAGE_SIZES } from './list';


const CrudListPagination = ({
  pagination,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalPages
}) => {

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="flex justify-start gap-5">
      {pagination?.previous && (
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="flex justify-center gap-2 bg-secondary text-sm text-white px-2 items-center rounded"
        >
          {"< Précédent"}
        </button>
      )}
      <div className="flex justify-center items-center">
        Page {currentPage} sur {totalPages}
      </div>
      {pagination?.next && (
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="flex justify-center gap-2 bg-secondary text-sm text-white px-2 items-center rounded"
        >
          {"Suivant >"}
        </button>
      )}
      <Dropdown
        value={pageSize}
        defaultValue={pageSize}
        placeholder={pageSize}
        onChange={(e) => setPageSize(e.value)}
        options={PAGE_SIZES}
        optionLabel="name"
        className='text-xs p'
        style={{ width: "90px" }}
      />
    </div>
  );
};


export default CrudListPagination;
