import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';


import 'primereact/resources/primereact.min.css';

import { INTERNAL_ROUTES } from '../../utils/internal_routes.js';

import CardStatOne from '../../components/cards/stats/CardStatOne.js';
import Heading from './../../components/Utils/Heading';
import Loading from '../../components/Utils/Loading.js';
import { get_medical_covers } from '../../services/management/medical_covers.service.js';

const MedicalCover = () => {
    const [medical_covers, setMedicalCovers] = useState([]);
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState('');

    const toast = useRef(null);


    useEffect(() => {
        const fetch_data = async () => {
            try {
                // FIXME: Pagination
                const data = await get_medical_covers(1000, 0);
                setMedicalCovers(data.results || data);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetch_data();
    }, []);

    // Modal handler : 
    const types_fr = {
        'ponctual': 'Ponctuel',
        'other': 'Autre',
    }

    // DataTable columns template
    const status_template = (m_cover) => {
        return types_fr[m_cover.coverage_type]
    };

    const action_template = (row_data) => {
        return (
            <div className="flex justify-center gap-2 items-center">
                <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.MEDICAL_COVERS_DETAIL}/${row_data.id}`} className="flex justify-start gap-2 hover:-translate-y-1"> <Icon icon={'ph:list-magnifying-glass'} className="text-gray-600 xl:text-lg" /></NavLink>
            </div>
        );
    }


    return (
        <>
            <Toast ref={toast} />


            {/* Page content */}
            <div className="p-4 lg:p-8">
                <Heading title="Liste des couvertures médicales" />
                {loading ? (
                    <div className="flex justify-center items-center h-screen">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {error ? (
                            <p className="text-center my-8 text-lg text-red-600">Une erreur est survenue : {error}</p>
                        ) :
                            (
                                <>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center my-8">
                                            <CardStatOne icon="bx:plus-medical" title="Couvertures médicales(s)" value={medical_covers.length} bg_color="bg-primary" />
                                        </div>

                                    </div>



                                    {medical_covers.length === 0 ? (
                                        <p className="text-center my-8 text-xl font-semibold"> Aucune couverture médicale enregistrée !</p>
                                    ) :
                                        (
                                            <div className="card my-4">
                                                <DataTable value={medical_covers} showGridlines={true} sortMode="multiple" removableSort paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field="first_name" header="Nom & Prénom(s) du demandeur" style={{ width: '45%' }}></Column>
                                                    <Column field="coverage_type" header="Type de couverture" body={status_template} style={{ width: '20%' }}></Column>
                                                    <Column field="start_date" header="Date de début" body={(row_data) => format(new Date(row_data.start_date), 'dd MMMM yyyy', { locale: fr })} style={{ width: '20%' }}></Column>
                                                    <Column field="duration" header="Durée(jours)" style={{ width: '10%' }}></Column>
                                                    <Column header="Action" body={action_template} style={{ width: '5%' }}></Column>
                                                </DataTable>
                                            </div>
                                        )}
                                </>
                            )}
                    </>
                )}
            </div>
        </>
    )
}
export default MedicalCover