import axios from "axios";
import { API_SOCKETS_BASE_URL, LOGIN_URL, REFRESH_TOKEN_URL } from "./urls";
import Cookies from "js-cookie";

export const login = async (credentials) => {
    const response = await axios.post(LOGIN_URL, {
        'username': credentials.username,
        'password': credentials.password,
    });
    if (response.data.access) {
        const { access, refresh } = response.data;
        const expires_in = credentials.remember_me ? 1 : 0.5;

        Cookies.set('access', access, { expires: expires_in });
        Cookies.set('refresh', refresh, { expires: expires_in });

        return response.data.user;
    } else {
        return null;
    }
};

export const logout = () => {
    try {
        Cookies.remove('access');
        Cookies.remove('refresh');
        return true;
    } catch (error) {
        return false;
    }
};

export const get_current_user = async () => {
    try {
        const refresh_token = Cookies.get('refresh');
        if (!refresh_token) return null;

        const response = await axios.post(REFRESH_TOKEN_URL, {
            refresh: refresh_token
        });
        if (response.data.access) {
            Cookies.set('access', response.data.access);
        }
        return response.data.user;
    } catch (error) {
        return null;
    }
};

export const createAuthWebSocket = ({ resource, roomName }) => {
    const token = Cookies.get('access');
    return new WebSocket(`${API_SOCKETS_BASE_URL}/${resource}/${roomName}/?token=${token}`);
}


// axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         if (error.response.status === 401) {
//             console.error("Access unauthorized: ", error);
//             window.location.href = '/';

//         } else if (error.response.status === 403) {
//             console.error("Access forbidden: ", error);
//             logout();
//             window.location.href = '/forbidden';
//         }
//         return Promise.reject(error);
//     }
// );