import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import PrivateRoute from './PrivateRoute';
import { INTERNAL_ROUTES } from "../utils/internal_routes";
import AmbulancesList from "../pages/admin/AmbulancesList";
import AdminBackOffice from "../layouts/dashboards/Admin";
import StaffBackOffice from "./../layouts/dashboards/Staff";
import StaffDashboard from "../pages/common/StaffDashboard";
import InterventionList from "../pages/common/InterventionList";
import AdminDashboard from "../pages/admin/AdminDashboard";
import MedicalMemberList from "../pages/admin/MedicalMemberList";
import PlanningView from "../pages/supervisor/PlanningView";
import AvailableMembers from "../pages/staff/AvailableMembers";
import InterventionManager from "../pages/arm/InterventionManager";
import InterventionSummary from "../pages/common/InterventionSummary";
import InterventionMap from "../pages/common/InterventionMap";
import Trend from "../pages/supervisor/stats/Trend";
import GeographicalCard from "../pages/supervisor/stats/GeographicalCard";
import InterventionStat from "../pages/supervisor/stats/InterventionStat";
import KPI from "../pages/supervisor/stats/KPI";
import AmbulanceDetail from "../pages/admin/AmbulanceDetail";
import ComingSoon from "../pages/ComingSoon";
import Error403 from './../pages/errors/Error403';
import Error404 from './../pages/errors/Error404';
import MedicalMemberDetail from "../pages/admin/MedicalMemberDetail";
import MedicalCover from "../pages/supervisor/MedicalCover";
import MedicalCoverDetail from './../pages/supervisor/MedicalCoverDetail';
import StockMaterial from "../pages/admin/StockMaterial";
import StockMaterialHistory from "../pages/admin/StockMaterialHistory";
import BranchList from "../pages/admin/BranchList";
import BranchDetail from "../pages/admin/BranchDetail";
import ServicesList from "../pages/admin/ServicesList";
import ServicesRooms from "../pages/admin/ServiceRooms";
import ClientServices from "../pages/admin/ClientServices";
import ClientServicesRating from "../pages/admin/ClientServicesRating";
import ServicesRoomsBeds from "../pages/admin/ServiceRoomsBeds";
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from "../components/crud/constants";
import AmbulancesTypeCrud from "../pages/admin/AmbulancesTypeCrud";
import AmbulancesQualificationCrud from "../pages/admin/AmbulancesQualificationCrud";
import InterventionReasonCrud from "../pages/admin/InterventionReasonCrud";
import MedicalRecords from "../pages/admin/MedicalRecords";
import Settings from "../pages/admin/Settings";
import MonitoringParams from "../pages/admin/MonitoringParams";
import MonitoringParamsChoices from "../pages/admin/MonitoringParamsChoices";
import ClientServicePricing from "../pages/admin/ClientServicesPricing";
import InterventionTeamMonitoring from "../pages/admin/InterventionTeamMonitoring";
import ScheduledInterventions from "../pages/common/ScheduledInterventions";

const router = createBrowserRouter([
  {
    path: INTERNAL_ROUTES.LOGIN,
    element: <Login />
  },
  {
    path: INTERNAL_ROUTES.FORBIDDEN,
    element: <Error403 />
  },
  {
    element: <PrivateRoute roles={["admin", "chief-supervisor"]} />,
    children: [
      {
        path: INTERNAL_ROUTES.ADMIN_BACKOFFICE,
        element: <AdminBackOffice />,
        children: [
          {
            path: INTERNAL_ROUTES.ADMIN_DASHBOARD,
            element: <KPI />
            // element: <AdminDashboard />
          },
          {
            path: INTERNAL_ROUTES.COMPANIES,
            element: <ComingSoon />,
            // element: <BranchList />
          },
          {
            path: INTERNAL_ROUTES.OTHERS_COMPANIES,
            element: (
              <BranchList
                crudType={CRUD_VIEW_TYPE.TABLE}
                staticFilters={{ others: true }}
                actions={{ create: false, update: false, delete: false }}
                resource={{
                  label: "centre partenaire",
                  labelPlural: "centres partenaires",
                  pronoun: "un",
                  pronounChange: "ce",
                }}
                routeActions={[
                  {
                    route: (company_id) => `/app/administration/app/services/${company_id}/`,
                    label: "Services"
                  }
                ]}
                additionalField={[
                  {
                    name: "free_beds_count",
                    type: CRUD_FIELD_TYPE.NUMBER,
                    label: "Lits disponibles",
                    labelPlural: "Lits disponibles",
                  },
                ]}
              />
            )
          },
          {
            path: INTERNAL_ROUTES.COMPANIES_DETAIL + '/:branch_id',
            // element: <BranchDetail />
            element: <ComingSoon />,
          },
          {
            path: INTERNAL_ROUTES.SERVICES,
            element: <ServicesList />
          },
          {
            path: INTERNAL_ROUTES.SERVICES_ROOMS,
            element: <ServicesRooms />
          },
          {
            path: INTERNAL_ROUTES.SERVICES_ROOMS_BEDS,
            element: <ServicesRoomsBeds />
          },
          {
            path: INTERNAL_ROUTES.MONITORING_PARAMS,
            element: <MonitoringParams />
          },
          {
            path: INTERNAL_ROUTES.MONITORING_PARAMS_CHOICE,
            element: <MonitoringParamsChoices />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTION_TEAM_MONITORING,
            element: <InterventionTeamMonitoring />
          },
          {
            path: INTERNAL_ROUTES.CLIENT_SERVICES,
            element: <ComingSoon />,
            // element: <ClientServices />
          },
          {
            path: INTERNAL_ROUTES.CLIENT_SERVICES_PRICES,
            element: <ClientServicePricing />
          },
          {
            path: INTERNAL_ROUTES.CLIENT_SERVICES_RATINGS,
            element: <ClientServicesRating />
          },
          {
            path: INTERNAL_ROUTES.MEDICAL_STAFF,
            element: <MedicalMemberList />
          },
          {
            path: INTERNAL_ROUTES.MEDICAL_STAFF_DETAIL + '/:member_id',
            element: <MedicalMemberDetail />
          },
          {
            path: INTERNAL_ROUTES.MEDICAL_RECORD,
            element: <ComingSoon />,
            // element: <MedicalRecords />
          },
          {
            path: INTERNAL_ROUTES.AMBULANCES,
            element: <AmbulancesList />
          },
          {
            path: INTERNAL_ROUTES.AMBULANCES_TYPES,
            element: <AmbulancesTypeCrud />
          },
          {
            path: INTERNAL_ROUTES.AMBULANCES_QUALIFICATIONS,
            element: <AmbulancesQualificationCrud />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTION_REASONS,
            element: <InterventionReasonCrud />
          },
          {
            path: INTERNAL_ROUTES.AMBULANCE_DETAIL + '/:ambulance_id',
            element: <AmbulanceDetail />
          },
          {
            path: INTERNAL_ROUTES.SETTINGS,
            element: <Settings />
          },
          {
            path: INTERNAL_ROUTES.FINANCES,
            element: <ComingSoon />
          },
          {
            path: INTERNAL_ROUTES.STOCK_MATERIALS,
            element: <StockMaterial />
          },
          {
            path: INTERNAL_ROUTES.STOCK_MATERIALS_DETAIL,
            element: <StockMaterialHistory />
          },
          {
            path: INTERNAL_ROUTES.MEDICAL_COVERS,
            element: <MedicalCover />
          },
          {
            path: `${INTERNAL_ROUTES.MEDICAL_COVERS_DETAIL}/:medical_cover_id`,
            element: <MedicalCoverDetail />
          },
          {
            path: `${INTERNAL_ROUTES.SCHEDULED_INTERVENTION}`,
            element: <ScheduledInterventions />
          },
        ]
      },

    ]
  },
  {
    element: <PrivateRoute roles={["chief-supervisor", "admin"]} />,
    children: [
      {
        path: INTERNAL_ROUTES.ADMIN_BACKOFFICE,
        element: <AdminBackOffice />,
        children: [
          {
            path: INTERNAL_ROUTES.ADMIN_DASHBOARD,
            element: <AdminDashboard />
          },
          {
            path: INTERNAL_ROUTES.PLANIFICATION,
            element: <PlanningView />
          },
          {
            path: INTERNAL_ROUTES.KPI_STATS,
            element: <KPI />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTIONS_STATS,
            element: <InterventionStat />
          },
          {
            path: INTERNAL_ROUTES.GEOGRAPHICAL_STATS,
            element: <GeographicalCard />
          },
          {
            path: INTERNAL_ROUTES.PERFORMANCE_STATS,
            element: <ComingSoon />
          },
          {
            path: INTERNAL_ROUTES.TREND_STATS,
            element: <Trend />
          },
          {
            path: INTERNAL_ROUTES.ADMIN_LIBRARY,
            element: <ComingSoon />
          },
          {
            path: INTERNAL_ROUTES.ADMIN_INTERVENTIONS,
            element: <InterventionList />
          },
          {
            path: `${INTERNAL_ROUTES.ADMIN_INTERVENTION_FORM}/:intervention_id`,
            element: <InterventionManager />
          },
          {
            path: INTERNAL_ROUTES.ADMIN_INTERVENTION_SUMMARY + '/:intervention_id',
            element: <InterventionSummary />
          },
          {
            path: INTERNAL_ROUTES.ADMIN_INTERVENTION_MAP + '/:intervention_id',
            element: <InterventionMap />
          },
        ]
      },

    ]
  },
  {
    element: <PrivateRoute roles={["arm", "admin", "medical-regulator", "chief-supervisor", "ambulance-chief", "ambulance-member"]} />,
    children: [
      {
        path: INTERNAL_ROUTES.STAFF_BACKOFFICE,
        element: <StaffBackOffice />,
        children: [
          {
            path: INTERNAL_ROUTES.STAFF_DASHBOARD,
            element: <StaffDashboard />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTIONS,
            element: <InterventionList />
          },
          {
            path: `${INTERNAL_ROUTES.INTERVENTION_FORM}/:intervention_id`,
            element: <InterventionManager />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTION_SUMMARY + '/:intervention_id',
            element: <InterventionSummary />
          },
          {
            path: INTERNAL_ROUTES.INTERVENTION_MAP + '/:intervention_id',
            element: <InterventionMap />
          },
          {
            path: INTERNAL_ROUTES.TEAMS,
            element: <AvailableMembers />
          },
          {
            path: INTERNAL_ROUTES.LIBRARY,
            element: <ComingSoon />
          },
          {
            path: INTERNAL_ROUTES.MEDICAL_COVERS,
            element: <MedicalCover />
          },
          {
            path: `${INTERNAL_ROUTES.MEDICAL_COVERS_DETAIL}/:medical_cover_id`,
            element: <MedicalCoverDetail />
          },
          {
            path: `${INTERNAL_ROUTES.SCHEDULED_INTERVENTION}`,
            element: <ScheduledInterventions />
          },
        ]
      },
    ]
  },
  {
    path: "*",
    element: <Error404 />
  },
]);

export default router