import React, { useContext, useEffect, useRef, useState } from 'react';
import { get_intervention_reasons } from '../../services/management/intervention_reason.service';
import CustomInputTextarea from '../../components/forms/inputs/CustomInputTextarea';
import CustomInputText from '../../components/forms/inputs/CustomInputText';
import CustomInputNumber from '../../components/forms/inputs/CustomInputNumber';
import CustomDropdown from '../../components/forms/inputs/CustomDropdown';
import CustomMultiSelect from '../../components/forms/inputs/CustomMultiSelect';
import BooleanInput from '../../components/Utils/BooleanInput';


const useInterventionFormFields = ({ data, intervention }) => {
  const [interventionReasons, setInterventionReasons] = useState([])
  const [stateIsReady, setStateIsReady] = useState(false)
  const numbersFields = [
    "age",
    "hemodynamic_pulse",
    "hemodynamic_weight",
    "hemodynamic_t",
    "vital_param_temperature",
  ]

  useEffect(() => {
    const setInterventionReasonsData = async () => {
      try {
        const response = await get_intervention_reasons();
        setInterventionReasons(response.results)
      } catch (e) {
        // toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue lors de la récupération des données.", life: 5000 });
      }
    };
    setInterventionReasonsData();
  }, []);

  useEffect(() => {
    if (interventionReasons?.length && data && intervention) setStateIsReady(true)
  }, [data, interventionReasons, intervention])

  /** Define first form fields */
  const baseInfoFields = [
    {
      key: "reasons",
      placeholder: "Raison(s) de l'intervention",
      Component: CustomMultiSelect,
      componentProps: { options: interventionReasons, optionLabel: "name" }
    },
  ]
  const patientPersonalInfoFields = [
    {
      key: "patient_names",
      placeholder: "Nom et prénoms du patient",
      Component: CustomInputText,
    },
    {
      key: "sex",
      placeholder: "Sexe du patient",
      Component: CustomDropdown,
      componentProps: {
        options: [
          { name: 'Homme', value: 'male' },
          { name: 'Femme', value: 'female' },
          { name: 'Autre', value: 'other' }
        ],
        optionLabel: "name"
      }
    },
    {
      key: "age",
      placeholder: "Age du patient",
      Component: CustomInputNumber,
    },
  ]
  const patientHemodynamicFields = [
    {
      key: "hemodynamic_ta",
      placeholder: "TA",
      Component: CustomInputText,
    },
    {
      key: "hemodynamic_pulse",
      placeholder: "Pouls",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_t",
      placeholder: "T°",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_coloration",
      placeholder: "Coloration",
      Component: CustomInputText,
    },
    {
      key: "hemodynamic_weight",
      placeholder: "Poids",
      Component: CustomInputNumber,
    },
    {
      key: "hemodynamic_others",
      placeholder: "Autres",
      Component: CustomInputTextarea,
      componentProps: { rows: 5 }
    },
  ]
  const patientHealthFields = [
    {
      key: "patient_is_conscious",
      placeholder: "Le patient est-il conscient ?",
      Component: BooleanInput,
    },
    {
      key: "patient_is_breathing",
      placeholder: "Le patient respire t-il ?",
      Component: BooleanInput,
    },
  ]
  const interventionLocationFields = [
    {
      key: "location",
      placeholder: "Lieu de l'intervention",
      Component: CustomInputText,
    },
    {
      key: "geo_location",
      placeholder: "Situation geographique ou plan",
      Component: CustomInputText,
    },
  ]
  const closeRelativeFields = [
    {
      key: "close_relative_names",
      placeholder: "Nom-prénoms du parent proche du / patient ou de la patiente",
      Component: CustomInputText,
    },
    {
      key: "close_relative_contact",
      placeholder: "Numéro du parent proche du / patient ou de la patiente",
      Component: CustomInputText,
    },
    {
      key: "form_retriever_names",
      placeholder: "Fiche d'intervention retirée par",
      Component: CustomInputText,
    },
  ]

  /** Define second form fields */
  const patientTreatmentsFields = [
    {
      key: "brief_clinical_exam",
      placeholder: "Examen clinique sommaire",
      Component: CustomInputTextarea,
      componentProps: { rows: 5 }
    },
    {
      key: "treatments_performed",
      placeholder: "Soins administrés",
      Component: CustomInputTextarea,
      componentProps: { rows: 5 }
    },
    {
      key: "events_occured_during_trip",
      placeholder: "Evénements en cours de trajet/observations",
      Component: CustomInputTextarea,
      componentProps: { rows: 5 }
    },
    {
      key: "diagnosis",
      placeholder: "Diagnostic retenu",
      Component: CustomInputTextarea,
    },
    {
      key: "recovery_of_intervention_costs",
      placeholder: "Recouvrement des frais d'intervention ?",
      Component: BooleanInput,
    },
  ]
  const patientVitalParamsFields = [
    {
      key: "vital_param_pa",
      placeholder: "PA",
      Component: CustomInputText,
    },
    {
      key: "vital_param_fc",
      placeholder: "FC",
      Component: CustomInputText,
    },
    {
      key: "vital_param_fr",
      placeholder: "FR",
      Component: CustomInputText,
    },
    {
      key: "vital_param_sao2",
      placeholder: "Sa02",
      Component: CustomInputText,
    },
    {
      key: "vital_param_glasgow_score",
      placeholder: "Score Glasgow",
      Component: CustomInputText,
    },
    {
      key: "vital_param_temperature",
      placeholder: "Température",
      Component: CustomInputNumber,
    },
  ]
  const patientMedicalGesturesFields = [
    {
      key: "medical_gesture_o2",
      placeholder: "0₂",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_iot",
      placeholder: "IOT",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_guedei",
      placeholder: "Guedel",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_vvp",
      placeholder: "WVP",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_sng",
      placeholder: "SNG",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_sv",
      placeholder: "SV",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_ml",
      placeholder: "ML",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_mce",
      placeholder: "MCE",
      Component: BooleanInput,
    },
    {
      key: "medical_gesture_debrifiler",
      placeholder: "Défibrillateur",
      Component: BooleanInput,
    },
  ]
  const relatedDoctorFields = [
    {
      key: "syndromic_summary",
      placeholder: "Résumé syndromique (Par le médecin)",
      Component: CustomInputTextarea,
    },
  ]
  const patientHospitalizationFields = [
    {
      key: "patient_hospitalized",
      placeholder: "Le patient a-t-il été hospitalisé ?",
      Component: BooleanInput,
    },
    {
      key: "hospitalization_hospital",
      placeholder: "Hôpital",
      Component: CustomInputText,
    },
    {
      key: "hospitalization_service",
      placeholder: "Service",
      Component: CustomInputText,
    },
    {
      key: "patient_went_to_reha",
      placeholder: "Le patient a-t-il été en réhanimation ?",
      Component: BooleanInput,
    },
    {
      key: "reha_place",
      placeholder: "Si oui, Où ?",
      Component: CustomInputText,
    },
    {
      key: "patient_evacuated",
      placeholder: "Le patient a-t-il été evacué ?",
      Component: BooleanInput,
    },
  ]
  const patientLifeForecastJ1Fields = [
    {
      key: "patient_alive_j1",
      Component: BooleanInput,
      placeholder: "Vivant ou Décédé ?",
      componentProps: { trueText: "Vivant", falseText: "Décédé" }
    },
    {
      key: "patient_alive_j1_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]
  const patientLifeForecastJ5Fields = [
    {
      key: "patient_alive_j5",
      Component: BooleanInput,
      placeholder: "Vivant ou Décédé ?",
      componentProps: { trueText: "Vivant", falseText: "Décédé" },
    },
    {
      key: "patient_alive_j5_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]
  const patientLifeForecastJ10Fields = [
    {
      key: "patient_alive_j10",
      placeholder: "Vivant ou Décédé ?",
      Component: BooleanInput,
      componentProps: { trueText: "Vivant", falseText: "Décédé" },
    },
    {
      key: "patient_alive_j10_notes",
      placeholder: "Remarques",
      Component: CustomInputTextarea,
    },
  ]

  return {
    stateIsReady,
    interventionReasons,
    numbersFields,
    fieldsGroups: {
      baseInfoFields,
      patientPersonalInfoFields,
      patientHemodynamicFields,
      patientHealthFields,
      interventionLocationFields,
      closeRelativeFields,
      patientTreatmentsFields,
      patientVitalParamsFields,
      patientMedicalGesturesFields,
      relatedDoctorFields,
      patientHospitalizationFields,
      patientLifeForecastJ1Fields,
      patientLifeForecastJ5Fields,
      patientLifeForecastJ10Fields
    }
  }
};

export default useInterventionFormFields;
