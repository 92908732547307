import { capitalizeStr, getLastNumberInText } from '../../utils/function';
import LoadingButton from '../Utils/LoadingButton';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CRUD_FIELD_TYPE } from './constants';
import CustomInputText from '../forms/inputs/CustomInputText';
import CustomInputNumber from '../forms/inputs/CustomInputNumber';
import CustomDropdown from '../forms/inputs/CustomDropdown';
import BooleanInput from '../Utils/BooleanInput';
import axios from 'axios';
import { API_BASE_URL } from '../../services/urls';
import FormField from '../forms/field';
import CustomMultiSelect from '../forms/inputs/CustomMultiSelect';
import { Button } from 'primereact/button';
import CustomRelatedForeignKey from '../forms/inputs/CustomRelatedForeignKey';
import CustomInputTextarea from '../forms/inputs/CustomInputTextarea';


const fieldTypeMapComponent = {
  [CRUD_FIELD_TYPE.STRING]: CustomInputText,
  [CRUD_FIELD_TYPE.TEXT]: CustomInputTextarea,
  [CRUD_FIELD_TYPE.NUMBER]: CustomInputNumber,
  [CRUD_FIELD_TYPE.ENUM]: CustomDropdown,
  [CRUD_FIELD_TYPE.ENUM_LIST]: CustomMultiSelect,
  [CRUD_FIELD_TYPE.BOOLEAN]: BooleanInput,
  [CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY]: CustomRelatedForeignKey,
  [CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY]: CustomRelatedForeignKey,
  [CRUD_FIELD_TYPE.DATE]: Calendar,
}

const CrudForm = ({
  initialValue = null,
  fields,
  crudType,
  resource,
  onCreate,
  onUpdate,
  notify,
  staticFilters,
  staticFormData = {}
}) => {
  const [data, setData] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const formFields = useMemo(() => (fields.map(field => {
      let componentProps = {style: {}}

      switch (field.type) {
        case CRUD_FIELD_TYPE.ENUM:
          componentProps = {
            options: field.choices,
            optionLabel: "label"
          }
          break
        case CRUD_FIELD_TYPE.ENUM_LIST:
          componentProps = {
            options: field.choices,
            optionLabel: "label"
          }
          break
        case CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY:
          componentProps = {
            resource: field.relatedResource,
            initialValue: initialValue && initialValue[field.name]
          }
          break
        case CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY:
          componentProps = {
            multiple: true,
            resource: field.relatedResource,
            initialValue: initialValue && initialValue[field.name] ? initialValue[field.name] : []
          }
          break
        default:
          break
      }

      return {
        key: field.name,
        placeholder: capitalizeStr(field.label || field.name),
        Component: fieldTypeMapComponent[field.type],
        componentProps
      }
    })
  ), [fields])

  useEffect(() => {
    const initForm = async () => {
      if (!initialValue) return

      setData(initialValue)
    };
    initForm();
  }, [initialValue]);

  const updateFormField = (fieldName, value) => {
    if (formFields.includes(fieldName)) value = getLastNumberInText(value)
    let _data = { ...data }
    _data[fieldName] = value
    setData(_data)
  }

  const getFieldProps = (field) => {
    let _props = { ...field }

    if (data) {
      if (field.Component === BooleanInput) {
        _props.checked = data[field.key]
      } else {
        _props.value = data[field.key]
      }
    }

    if (field.Component === CustomRelatedForeignKey) {
      _props.onChange = (e) => updateFormField(field.key, e)
    }
    else {
      _props.onChange = (e) => updateFormField(field.key, e?.target?.value || e?.value)
    }

    if (field.Component === Calendar) {
      _props.dateFormat = "dd/mm/yy"
      _props.value = new Date(data[field.key])
    }

    return _props
  }

  const validateForm = () => {
    let _errors = {}
    for (let field of fields) {
      if (field?.required && !(data && data[field.name])) {
        _errors[field.name] = ["Champ requis"]
      }
    }
    setErrors(_errors)
    return !Boolean(Object.keys(_errors).length)
  }

  const getParsedData = () => {
    let parsedData = {}
    for (let field of fields) {
      if (!data[field.name]) continue

      if (field.type === CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY) {
        parsedData[field.name] = data[field.name].id || data[field.name].value
      }
      else if (field.type === CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY) {
        parsedData[field.name] = data[field.name].map(i => i.id || i.value)
      }
      else if (field.type === CRUD_FIELD_TYPE.DATE) {
        parsedData[field.name] = data[field.name].toISOString().slice(0, 10)
      }
      else {
        parsedData[field.name] = data[field.name]
      }
    }

    return Object.assign(parsedData, staticFormData)
  }

  const createResource = async () => {
    if (!validateForm()) return

    setLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/${resource.endpointPrefix}/`,
        getParsedData()
      );
      setLoading(false);
      onCreate()
      notify('success', 'Succès', `Ajout éffectué avec succès`);
    } catch (e) {
      setErrors(e?.response?.data || {})
      setLoading(false);
      notify('error', 'Erreur de l\'ajout', e.message || e);
    }
  }

  const updateResource = async () => {
    if (!validateForm()) return

    setLoading(true);
    try {
      await axios.patch(
        `${API_BASE_URL}/${resource.endpointPrefix}/${initialValue.id}/`,
        getParsedData()
      );
      setLoading(false);
      onUpdate()
      notify('success', 'Succès', `Modification éffectuée avec succès`);
    } catch (e) {
      setErrors(e?.response?.data || {})
      setLoading(false);
      notify('error', 'Erreur de l\'ajout', e.message || e);
    }
  }

  return (
    <>
      <div>
        <div className="flex flex-col">
          {formFields.map(formField => (
            <FormField
              {...getFieldProps(formField)}
              style={{...(formField.componentProps?.style || {}), width: "100%"}}
              error={errors && errors[formField.key]}
            />
          ))}
        </div>
        <div className="flex item-center justify-center">
          <Button
            onClick={() => initialValue ? updateResource() : createResource()}
            className={`rounded bg-primary text-white px-4 py-2`}
            label={"Enregistrer"}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export default CrudForm
