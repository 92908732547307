import React, { useEffect, useRef, useState } from 'react';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import 'primereact/resources/primereact.min.css';

import { API_BASE_URL } from '../../services/urls.js';
import { CRUD_VIEW_TYPE } from './constants.js';
import axios from 'axios';
import Loading from '../Utils/Loading.js';
import Heading from '../Utils/Heading.js';
import ConfirmModal from '../modals/ConfirmModal.js';
import { Dialog } from 'primereact/dialog';
import CrudForm from './form.js';
import { Toast } from 'flowbite-react';
import CardStatOne from '../cards/stats/CardStatOne.js';
import CrudListPagination from './listPagination.js';
import CrudListView from './listView.js';
import { capitalizeStr } from '../../utils/function.js';
import { DefaultCrudCardComponent } from './listViewCardDefault.js';

export const DEBOUNCE_TIME = 500
export const PAGE_SIZES = [5, 10, 20, 50, 100]


const CrudList = ({
  type = CRUD_VIEW_TYPE.TABLE,
  resource = {
    name: "",
    label: "",
    labelPlural: ""
  },
  fields,
  readOnlyFields=[],
  writeOnlyFields=[],
  cardSkipFields=[],
  filtersFields,
  staticFilters={},
  staticFormData={},
  searchEnabled = true,
  readOnly = false,
  actions = {
    create: true,
    update: true,
    delete: true
  },
  hideActions = false,
  debounceTime = DEBOUNCE_TIME,
  defaultPageSize = 5,
  routeActions,
  customActions,
  CardComponent = DefaultCrudCardComponent,
  onDetailClick,
  autoSelectFirst = false,
  onAutoSelectFirst
}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const toast = useRef(null);

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [enabledActions, setEnabledActions] = useState({});

  useEffect(() => {
    let _actions = {...actions}
    if (readOnly) {
      Object.assign(_actions, { create: false, update: false, delete: false})
    }
    setEnabledActions(_actions)
  }, [])

  // Debounce search term update
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, debounceTime);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    fetchData(currentPage);
  }, [resource.endpointPrefix, currentPage, debouncedSearchTerm, ]); // staticFilters

  useEffect(() => {
    setCurrentPage(1)
    fetchData(1)
  }, [pageSize])

  const fetchData = async (page) => {
    try {
      setLoading(true)
      const response = await axios.get(`${API_BASE_URL}/${resource.endpointPrefix}`, {
        params: {
          ...staticFilters,
          limit: pageSize,
          offset: pageSize * (page - 1),
          search: debouncedSearchTerm
        }
      });

      if (autoSelectFirst && response.data.results?.length) {
        onAutoSelectFirst(response.data.results[0])
        return
      }
      setData(response.data.results);
      setPagination({
        count: response.data.count,
        previous: Boolean(response.data.previous),
        next: Boolean(response.data.next),
      })

      setTotalPages(Math.ceil(response.data.count / pageSize));
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  const notify = (severity, summary, detail) => {
    // toast.current.show({ severity: severity, summary: summary, detail: detail, life: 5000 });
  }

  const deleteItem = async () => {
    if (!selectedItem) return

    setLoading(true);
    try {
      await axios.delete(`${API_BASE_URL}/${resource.endpointPrefix}/${selectedItem.id}`);
      setShowDeleteConfirmation(false);
      setLoading(false);
      setCurrentPage(1);
      fetchData(1)
      notify('success', 'Succès de la suppression', 'Ambulance supprimée avec succès');
    } catch (e) {
      setLoading(false);
      notify('error', 'Erreur de la suppression', e.message);
    }
  }

  const onCloseForm = () => {
    setShowFormModal(false);
    setCurrentPage(1);
    fetchData(1)
  }

  if (!data?.length && loading) return (
    <div className="flex justify-center items-center h-screen">
      <Loading />
    </div>
  )

  return (
    <>
      <div className="p-4 lg:p-8">
        <Heading title={capitalizeStr(resource.labelPlural)} />
        <div className="flex justify-between text-gray-600">
          {searchEnabled && (
            <div className="flex justify-between">
              <CardStatOne icon={resource.icon} title={capitalizeStr(resource.labelPlural)} value={pagination?.count} bg_color="bg-primary" />
              <div className="relative text-gray-600">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <input
                    type="text"
                    className="border border-gray-300 bg-white h-10 px-6 pl-10 pr-4 rounded text-sm focus:outline-none"
                    placeholder="Recherchez..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </IconField>
              </div>
            </div>
          )}
          <div>
            {enabledActions.create && (
              <button
                onClick={() => { setSelectedItem(null); setShowFormModal(true) }}
                className="flex justify-center gap-2 bg-secondary text-white px-4 py-2 items-center rounded"
              >
                Ajouter
              </button>
            )}
          </div>
        </div>
        {!data.length ? (
          <p className="text-center my-8 text-xl font-semibold"> Aucune donnée !</p>
        ) :
          (
          <div className="card my-4">
            <CrudListView
              {...{
                type,
                resource,
                data,
                actions: enabledActions,
                hideActions,
                fields,
                cardSkipFields,
                setSelectedItem,
                setShowFormModal,
                setShowDeleteConfirmation,
                routeActions,
                customActions,
                onDetailClick,
                CardComponent
              }}
            />
          </div>
        )}
        <CrudListPagination
          {...{
            pagination,
            currentPage,
            setCurrentPage,
            pageSize,
            setPageSize,
            totalPages
          }}
        />
      </div>
      <Dialog
        header={(
          <p className="lg:text-xl font-semibold p-2">
            {selectedItem ? "Modifier" : "Ajouter"} {resource.pronoun} {resource.label}
          </p>
        )}
        visible={showFormModal}
        onHide={() => setShowFormModal(false)}
        // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        <div className="">
          <CrudForm
            resource={resource}
            initialValue={selectedItem}
            fields={fields.filter(
              f => (!writeOnlyFields?.length || writeOnlyFields.includes(f.name)) && !readOnlyFields.includes(f.name)
            )}
            crudType={type}
            onCreate={onCloseForm}
            onUpdate={onCloseForm}
            notify={notify}
            staticFilters={staticFilters}
            staticFormData={staticFormData}
          />
        </div>
      </Dialog>
      <ConfirmModal
        visible={showDeleteConfirmation}
        setVisible={() => setShowDeleteConfirmation(false)}
        title="Supprimer"
        content={`Voulez-vous vraiment supprimer ${resource.pronounChange} ${resource.label} ?`}
        icon="healthicons:warning"
        accept={() => {
          deleteItem();
          setShowDeleteConfirmation(false);
        }}
        reject={() => setShowDeleteConfirmation(false)}
      />
      {/* <Toast ref={toast} /> */}
    </>
  );
};


export default CrudList;
