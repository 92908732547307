import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const BranchList = ({
  staticFilters = {},
  actions={ create: true, update: true, delete: true },
  routeActions={},
  crudType=CRUD_VIEW_TYPE.CARD,
  additionalField=[],
  resource={}
}) => {

  const resourceData = useMemo(() => {
    return Object.assign({
      endpointPrefix: "companies",
      label: "antenne",
      labelPlural: "antennes",
      pronoun: "une",
      pronounChange: "cette",
      icon: "mdi:office-building-marker-outline",
      getTitle: (item) => item.name,
      getSubTitle: (item) => item.location,
      imageField: null
    }, resource)
  }, [])

  return (
    <CrudList
      type={crudType}
      resource={resourceData}
      fields={[
        {
          name: "name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "is_main",
          type: CRUD_FIELD_TYPE.BOOLEAN,
          many: true,
          label: "Antenne centrale",
        },
        {
          name: "location",
          type: CRUD_FIELD_TYPE.STRING,
          label: "Localisation",
          labelPlural: "",
        },
        ...additionalField
      ]}
      staticFilters={staticFilters}
      readOnlyFields={["is_main"]}
      cardSkipFields={["name"]}
      actions={actions}
      routeActions={routeActions}
      defaultPageSize={10}
    />
  )
}

export default BranchList;
