import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import localeFr from '@fullcalendar/core/locales/fr'
import PlanningDetail from './PlanningDetail';

const CalendarView = ({ plannings, remove_member, add_member, delete_planning, update_planning, all_members }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setDialogVisible(true);
  };

  useEffect(() => {
    if (selectedEvent) {
      const updated_event = plannings.find(pl => parseInt(pl.id) === parseInt(selectedEvent.id));
      setSelectedEvent(updated_event);
    }
  }, [plannings]);

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={plannings}
        eventClick={handleEventClick}
        locale={localeFr}
        headerToolbar={{
          left: 'today',
          center: 'title',
          right: 'prev,next'
        }}
      // eventContent={(arg, createElement) => {
      //   const { event } = arg;
      //   const backgroundColor = event.extendedProps.color || 'orange'; // Définir la couleur de fond par défaut ou depuis les extendedProps

      //   const classNames = [
      //     'custom-event-class', // Classe de style personnalisée
      //     event.overlap ? 'overlap' : '', // Ajouter une classe si l'événement se chevauche
      //   ];

      //   return createElement('div', {
      //     className: classNames.join(' '),
      //     style: {
      //       backgroundColor,
      //     },
      //     // Ajouter d'autres attributs ou handlers au besoin
      //   }, event.title);
      // }}
      />

      <PlanningDetail dialogVisible={dialogVisible} setDialogVisible={setDialogVisible} selectedEvent={selectedEvent} remove_member={remove_member} add_member={add_member} delete_planning={delete_planning} update_planning={update_planning} all_members={all_members} />

    </>
  );
};

export default CalendarView;