import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';
import MedicalRecordDetail from '../admin/MedicalRecordDetail.js';


const ScheduledInterventions = ({
  staticFilters = {},
  routeActions={}
}) => {
  const [detailRecord, setDetailRecord] = useState(null)

  return (
    <>
      <CrudList
        type={CRUD_VIEW_TYPE.CARD}
        resource={{
          endpointPrefix: "intervention_forms",
          label: "intervention programmée",
          labelPlural: "interventions programmées",
          pronoun: "une",
          pronounChange: "cette",
          icon: "healthicons:hospitalized",
          getTitle: (item) => item.name,
          imageField: null
        }}
        fields={[
          {
            name: "patient_names",
            type: CRUD_FIELD_TYPE.STRING,
            required: true,
            label: "Nom et prénoms",
            labelPlural: "",
          },
          {
            name: "reasons",
            type: CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY,
            relatedResource: {
              endpointPrefix: "intervention_reasons",
              getLabel: (item) => item.name
            },
            required: true,
            label: "Motif",
            labelPlural: "Motifs",
          },
        ]}
        readOnly={true}
        staticFilters={{scheduled: true}}
        defaultPageSize={10}
        onDetailClick={item => {
          setDetailRecord(item)
        }}
      />
      {detailRecord && (
        <MedicalRecordDetail
          name="Intervention programmée"
          visible={Boolean(detailRecord)}
          setVisible={(value) => !value && setDetailRecord(null)}
          data={detailRecord}
        />
      )}
    </>
  )
}

export default ScheduledInterventions;
