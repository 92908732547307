import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const ServicesRooms = ({
  staticFilters = {},
  actions={ create: false, update: false, delete: false },
  routeActions={}
}) => {

  const { service_id } = useParams()

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "services_rooms",
        label: "chambre",
        labelPlural: "chambres",
        pronoun: "une",
        pronounChange: "cette",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        getSubTitle: (item) => item.location,
        imageField: null
      }}
      fields={[
        {
          name: "number",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Numéro",
          labelPlural: "Numéros",
        },
        {
          name: "free_beds_count",
          type: CRUD_FIELD_TYPE.NUMBER,
          label: "Lits disponibles",
          labelPlural: "Lits disponibles",
        },
      ]}
      staticFilters={{service: service_id}}
      staticFormData={{service: service_id}}
      readOnlyFields={["free_beds_count"]}
      actions={actions}
      routeActions={[
        {
          route: (service_room_id) => `/app/administration/app/services_rooms_beds/${service_room_id}/`,
          label: "Lits"
        }
      ]}
      defaultPageSize={10}
    />
  )
}

export default ServicesRooms;
