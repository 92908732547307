import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes/routes';
import { UserProvider } from './context/UserContext';
import './assets/css/utils.css';
import { Sentry } from '.';

const App = () => {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
};

export default App;
