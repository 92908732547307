
import { ambulance_tag_options } from '../../../utils/repeating_variables';
import LoadingButton from '../../Utils/LoadingButton';
import CustomRadioSet from '../inputs/CustomRadioSet';

const UpdateAmbulance = ({ on_submit, loading, data }) => {
    let tag_options = ambulance_tag_options;
    if (data.tag) {
        let i = data.tag === "chassis" ? 0 : 1;
        tag_options.default = i;
    }
    return (
        <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
            <CustomRadioSet data={tag_options} />

            <div>
                <label htmlFor="identifier" className="block mb-2 text-base font-medium text-gray-900">N&deg; d'immatriculation <span className="text-red-500">*</span></label>
                <input type="text" name="identifier" id="identifier" defaultValue={data.identifier} className="bg-gray-50 focus:bg-gray-50 text-gray-900 text-base rounded-sm focus:ring-primary focus:border-primary block w-full p-2.5" required />
            </div>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <label htmlFor="status" className="block mb-2 text-base font-medium text-gray-900">Disponibilité <span className="text-red-500">*</span> </label>
                        <select id="status" name="status" defaultValue={data.status} className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" required>
                            <option value="available">Fonctionnel</option>
                            <option value="spoiled">Non fonctionnel</option>
                        </select>
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="relative w-full mb-2 pr-2">
                        <label htmlFor="qualifications" className="block mb-2 text-base font-medium text-gray-900">Classe de l'ambulance <span className="text-red-500">*</span> </label>
                        <select id="qualifications" name="qualifications" defaultValue={data.qualifications[0]} className="border px-3 py-3 placeholder-zinc-600 input-text-color bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" required>
                            <option value="level-1">Type A</option>
                            <option value="level-2">Type B</option>
                            <option value="level-3">Type C</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="my-4">
                <div className="relative w-full">
                    <label htmlFor="description" className="text-lg text-gray-600">Description de l'ambulance</label>
                    <textarea id="description" name="description" rows="4" defaultValue={data.description} className="block p-2 w-full text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-primary focus:border-primary" placeholder="Donnez une brève description de cette ambulance "></textarea>
                </div>
            </div>

            <LoadingButton label="Mettre à jour" loading={loading} />
        </form>
    )
}


export default UpdateAmbulance