import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const MonitoringParams = ({
  staticFilters = {},
  routeActions={}
}) => {

  const { company_id } = useParams()

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "monitoring_params",
        label: "Paramètre de suivi de performance des équipes",
        labelPlural: "paramètres",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.TEXT,
        },
      ]}
      routeActions={[
        {
          route: (param_id) => `/app/administration/app/monitoring_params_choices/${param_id}/`,
          label: "Scores de mesure du suivi"
        }
      ]}
      defaultPageSize={10}
    />
  )
}

export default MonitoringParams;
