import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { delete_ambulance, retrieve_ambulance, update_ambulance } from "../../services/management/ambulances.service";
import { Toast } from "primereact/toast";
import Loading from "../../components/Utils/Loading";
import { Icon } from '@iconify/react';
import no_image from '../../assets/images/no-image.jpg'
import FormModal from "../../components/modals/FormModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import UpdateAmbulance from "../../components/forms/ambulances/UpdateAmbulance";
import { INTERNAL_ROUTES } from "../../utils/internal_routes";
import { Tag } from "primereact/tag";

const AmbulanceDetail = () => {

    const { ambulance_id } = useParams()
    const [ambulance, setAmbulance] = useState(null)

    const toast = useRef(null);
    const [ready, setReady] = useState(false)

    const [btn_loading, setBtnLoading] = useState(false);

    const [update_visible, setUpdateVisible] = useState(false);
    const [delete_visible, setDeleteVisible] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        const get_ambulance = async () => {
            try {
                const ambulance_data = await retrieve_ambulance(ambulance_id);
                setAmbulance(ambulance_data)
                setReady(true)
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message || "Une erreur est survenue lors de la récupération de données.", life: 5000 });
            }
        }
        get_ambulance()
    }, [ambulance_id])


    // Operations management

    const handle_update_ambulance = async (event) => {
        event.preventDefault();
        const form_data = new FormData(event.target);
        const updated_data = Object.fromEntries(form_data.entries());
        setBtnLoading(true);
        if (!ambulance_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de modification', 'Veuillez selectionner une ambulance');
            return;
        }
        try {
            const data = await update_ambulance(updated_data, ambulance_id);
            const updated_ambulance = data.results || data;
            setAmbulance(updated_ambulance);
            setBtnLoading(false);
            setUpdateVisible(false);
            handle_notification('success', 'Succès de la mise à jour', 'Ambulance modifiée avec succès');
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la mise à jour', e);
        }
    }

    const handle_delete_ambulance = async () => {
        setBtnLoading(true);
        if (!ambulance_id) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de suppression', 'Veuillez selectionner une ambulance');
            return;
        }
        try {
            await delete_ambulance(ambulance_id);
            setBtnLoading(false);
            setDeleteVisible(false);
            navigate(INTERNAL_ROUTES.ADMIN_BACKOFFICE + '/' + INTERNAL_ROUTES.AMBULANCES);
        } catch (e) {
            setBtnLoading(false);
            handle_notification('error', 'Erreur de la suppression', e.message);
        }
    }


    // Messsage management
    const handle_notification = (severity, summary, detail) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail, life: 5000 });
    }

    const update_ambulance_preview = () => {
        setUpdateVisible(true);
    }

    const confirm_deletion = () => {
        setDeleteVisible(true);
    };
    const cancel_deletion = () => {
        setDeleteVisible(false);
    }

    const statuses_fr = {
        'available': 'Disponible',
        'spoiled': 'Indisponible',
    }
    const qualifications_fr = {
        'level-1': 'Type A',
        'level-2': 'Type B',
        'level-3': 'Type C',
    }

    // Modal headers
    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }
    const get_severity = (status) => {
        switch (status) {
            case 'available':
                return 'success';

            case 'spoiled':
                return 'warning';

            default:
                return null;
        }
    };

    if (!ready) {
        return (
            <>
                <Toast ref={toast} />
                <Loading />
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />

            <FormModal header={modal_header('Mettre à jour les informations d\'une ambulance')} children={<UpdateAmbulance on_submit={handle_update_ambulance} loading={btn_loading} data={ambulance} />} visible={update_visible} setVisible={setUpdateVisible} />
            <ConfirmModal visible={delete_visible} setVisible={setDeleteVisible} title="Supprimer une ambulance" content="Voulez-vous vraiment supprimer cette ambulance ?" icon="healthicons:warning" accept={() => { handle_delete_ambulance() }} reject={() => { cancel_deletion() }} />

            <div className="p-4 mb-4">
                <div className="card-wrapper flex flex-col justify-center gap-4 items-center">
                    <div className="mb-4">
                        <h2 className="ambulance-title text-secondary text-2xl lg:text-3xl uppercase font-semibold">Ambulance N° {ambulance.identifier}</h2>
                    </div>

                    <div className="stat-cards flex flex-row gap-y-4 gap-x-8 justify-center items-center flex-wrap w-full mb-8 lg:mb-16">
                        <div className="w-full mt-6 px-6 md:w-2/3 lg:w-3/5 xl:w-5/12 sm:mt-0">
                            <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-slate-100">
                                <div className="p-3 rounded-full bg-primary bg-opacity-75">
                                    <Icon icon={"ph:siren-fill"} className="text-white text-2xl" />
                                </div>

                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{0}</h4>
                                    <div className="text-gray-500">Nombre d'interventions liées à l'ambulance</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card flex flex-wrap justify-center gap-8 items-center">
                        <div className="img-display max-w-2xl">
                            <img className="hover:opacity-80 max-h-[36rem]" src={no_image} alt="Ambulance" />
                        </div>
                        <div className="ambulance-content px-8 py-4 max-w-2xl">
                            <div className="ambulance-detail my-8">
                                <h2 className="font-semibold text-secondary pb-2">Informations sur l'ambulance </h2>
                                <ul className="my-4 text-lg space-y-4">
                                    <li className="flex flex-nowrap gap-2 justify-start items-center"><Icon className="pl-4 text-secondary font-semibold w-10" icon={"ei:check"} /> N&deg; d'immatriculation: &nbsp; <span>{ambulance.identifier} </span></li>
                                    <li className="flex flex-nowrap gap-2 justify-start items-center"><Icon className="pl-4 text-secondary font-semibold w-10" icon={"ei:check"} /> Classe de l'ambulance: &nbsp;<span>{qualifications_fr[ambulance.qualifications[0]]}</span></li>
                                    <li className="flex flex-nowrap gap-2 justify-start items-center"><Icon className="pl-4 text-secondary font-semibold w-10" icon={"ei:check"} /> Type de l'ambulance: &nbsp;<span><Tag value={statuses_fr[ambulance.status]} severity={get_severity(ambulance.status)}></Tag></span></li>
                                </ul>

                                <h2 className="font-semibold text-secondary pb-2 mt-4 lg:mt-8">Description de l'ambulance </h2>

                                <div className="my-2 px-2">
                                    <p className="text-gray-500 text-justify text-wrap">{ambulance.description}</p>
                                </div>
                            </div>

                            <div className="flex mt-16 gap-4 items-center justify-stretch flex-wrap">
                                <div>
                                    <button onClick={() => update_ambulance_preview()} className="bg-secondary text-white rounded hover:shadow-md py-2 px-6 inline-flex items-center">
                                        <span className="mr-2 ">Modifier</span>
                                        <Icon icon={"fa-solid:pen"} />
                                    </button>
                                </div>
                                <div>
                                    <button className="bg-secondary text-white rounded hover:shadow-md py-2 px-6 inline-flex items-center">
                                        <span className="mr-2 ">Changer vignette</span>
                                        <Icon icon={"fa-solid:image"} />
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => confirm_deletion()} className="bg-white font-semibold hover:font-normal text-red-600 border border-red-600 hover:text-white hover:bg-red-600 rounded hover:shadow-md py-2 px-6 inline-flex items-center">
                                        <span className="mr-2 ">Supprimer</span>
                                        <Icon icon={"fa-solid:trash"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="items-center lg:flex bg-gray-50 dark:bg-gray-800 mt-8">
                    <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                        <div className="pt-4 bg-white rounded shadow dark:bg-gray-900">
                            <div className="flex px-6 pb-4 border-b dark:border-gray-700">
                                <h2 className="text-xl font-bold text-main-blue">Informations sur les références de ce bien immobilier</h2>
                            </div>
                            <div className="p-4 overflow-x-auto">
                                <table className="w-full table-auto border-collapse border border-gray-400">
                                    <thead>
                                        <tr className="text-base text-left text-gray-500 dark:text-gray-400">
                                            <th className="px-6 py-3 font-medium  border border-gray-400">N&deg;</th>
                                            <th className="px-6 py-3 font-medium  border border-gray-400">Spécification</th>
                                            <th className="px-6 py-3 font-medium  border border-gray-400">Valeur</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-sm bg-white dark:text-gray-400 dark:bg-gray-800">
                                            <td className="px-2 py-4 font-semibold  border border-gray-400">{1}</td>
                                            <td className="px-6 py-4 font-semibold  border border-gray-400">Lits multiple</td>
                                            <td className="px-6 py-4 font-semibold text-main-color border border-gray-400">OUI </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default AmbulanceDetail





