// TODO: Import from env
// export const API_PROTOCOL = "http";
// export const API_DOMAIN = "localhost:8000";
// export const API_BASE_URL = `${API_PROTOCOL}://${API_DOMAIN}/api`;
// export const API_SOCKETS_PROTOCOL = "ws";
// export const API_SOCKETS_BASE_URL = `${API_SOCKETS_PROTOCOL}://${API_DOMAIN}/api/ws`;

export const API_PROTOCOL = "https";
export const API_DOMAIN = "staff.samubenin.bj";
export const API_BASE_URL = `${API_PROTOCOL}://${API_DOMAIN}/api`;
export const API_SOCKETS_PROTOCOL = "wss";
export const API_SOCKETS_BASE_URL = `${API_SOCKETS_PROTOCOL}://${API_DOMAIN}/api/ws`;

export const LOGIN_URL = API_BASE_URL + "/auth/jwt/create/";
export const REFRESH_TOKEN_URL = API_BASE_URL + "/auth/jwt/refresh/"
export const AMBULANCES_URL = API_BASE_URL + "/ambulances/"

export const MEDICAL_STAFF_URL = API_BASE_URL + "/users/"

export const BRANCH_URL = API_BASE_URL + "/branches/"
export const BULK_BRANCH_MEMBERS_URL = API_BASE_URL + "/bulk/branch_members/"

export const PLANNING_URL = API_BASE_URL + "/plannings/"
export const PLANNING_MEMBERS_URL = API_BASE_URL + "/planning_members/"
export const BULK_PLANNING_MEMBERS_URL = API_BASE_URL + "/bulk/planning_members/"

export const INTERVENTION_URL = API_BASE_URL + "/interventions/"
export const INTERVENTION_FORM_URL = API_BASE_URL + "/intervention_forms/"
export const INTERVENTION_AMBULANCE_URL = API_BASE_URL + "/intervention_ambulances/"
export const INTERVENTION_AMBULANCE_TEAM_MEMBER_URL = API_BASE_URL + "/intervention_ambulance_team_members/"
export const INTERVENTION_REASON_URL = API_BASE_URL + "/intervention_reasons/"
export const BULK_INTERVENTION_TEAM_MEMBER_URL = API_BASE_URL + "/bulk/intervention_ambulance_team_members/"

export const STATS_URL = API_BASE_URL + "/statistics/"


export const MEDICAL_COVERS_URL = API_BASE_URL + "/coverage_forms/"
export const PRODUCTS_URL = API_BASE_URL + "/products/"
export const PRODUCTS_BASES_URL = API_BASE_URL + "/products_bases/"
export const STOCK_MOVEMENT_URL = API_BASE_URL + "/stock-movement/"
