import { INTERVENTION_AMBULANCE_URL } from "../urls";
import axios from '../customAxios';


export const get_intervention_ambulances = async (params) => {
    try {
        const response = await axios.get(INTERVENTION_AMBULANCE_URL, { params });
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const get_intervention_ambulance_geo_position = async (id) => {
    try {
        const response = await axios.get(`${INTERVENTION_AMBULANCE_URL}${id}/geo_positions`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const create_intervention_ambulance = async (data) => {
    try {
        const response = await axios.post(INTERVENTION_AMBULANCE_URL, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const update_intervention_ambulance = async (id, data) => {
    try {
        const response = await axios.patch(`${INTERVENTION_AMBULANCE_URL}${id}/`, data);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

export const delete_intervention_ambulance = async (id) => {
    try {
        const response = await axios.delete(`${INTERVENTION_AMBULANCE_URL}${id}/`);
        return response.data;
    } catch (error) {
        return handle_error(error);
    }
};

const handle_error = (error) => {
    if (error.response) {
        throw new Error(error.response.data.detail || error.response.data.message || error.response.data);
    } else if (error.request) {
        throw new Error(error.request);
    } else {
        throw new Error(error.message);
    }
};
