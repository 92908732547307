import React from 'react';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';
import CrudList from '../../components/crud/list.js';



const AmbulancesQualificationCrud = () => {

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "ambulance_qualifications",
        label: "critère ambulance",
        labelPlural: "critères ambulances",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:ambulance",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.STRING,
          label: "",
          labelPlural: "",
        },
      ]}
      actions={{ create: true, update: true, delete: true}}
      defaultPageSize={10}
    />
  )
}

export default AmbulancesQualificationCrud;
