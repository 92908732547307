import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const ClientServicePricing = ({
  staticFilters = {},
  routeActions={}
}) => {

  const { service_id } = useParams()

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "client_services_prices",
        label: "Notation du service",
        labelPlural: "choix",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "country",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "countries",
            getLabel: (country) => country.name
          },
          required: true,
          label: "Pays",
          labelPlural: "Pays",
        },
        {
          name: "city",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "cities",
            getLabel: (city) => city.name
          },
          required: false,
          label: "Ville/Localité",
          labelPlural: "Villes/Localités",
        },
        {
          name: "price",
          type: CRUD_FIELD_TYPE.NUMBER,
          label: "Prix (en FCFA)",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.TEXT,
        },
      ]}
      staticFilters={{client_service: service_id}}
      staticFormData={{client_service: service_id}}
      defaultPageSize={10}
    />
  )
}

export default ClientServicePricing;
