import React from 'react';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';
import CrudList from '../../components/crud/list.js';



const AmbulancesTypeCrud = () => {

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "ambulance_types",
        label: "type d'ambulance",
        labelPlural: "types d'ambulance",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:ambulance",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "qualifications",
          type: CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY,
          relatedResource: {
            endpointPrefix: "ambulance_qualifications",
            getLabel: (item) => item.name
          },
          required: true,
          label: "Qualification",
          labelPlural: "Qualifications",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.STRING,
          label: "",
          labelPlural: "",
        },
      ]}
      actions={{ create: true, update: true, delete: true}}
      defaultPageSize={10}
    />
  )
}

export default AmbulancesTypeCrud;
