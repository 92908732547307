import UserMenuDropdown from './UserMenuDropdown';
import { Icon } from '@iconify/react/dist/iconify.js';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { INTERNAL_ROUTES } from '../../utils/internal_routes';
import UserMenuNotification from './UserMenuNotification';


export const NavBarItems = () => {

    const { user } = useContext(UserContext);
    const has_role = (required_roles) => {
        return user.groups.some(group => required_roles.includes(group.name));
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">
            <NavLink to={INTERNAL_ROUTES.STAFF_DASHBOARD} className={({ isActive }) => isActive ? "custom-link-active" : "custom-link"} >
                <span className="mr-2">
                    <Icon icon="bxs:shapes" className="text-gray-500 hor-menu-icon" />
                </span>
                Accueil
            </NavLink>

            <NavLink to={INTERNAL_ROUTES.INTERVENTIONS} className={({ isActive }) => isActive ? "custom-link-active" : "custom-link"}>
                <span className="mr-2">
                    <Icon icon="ph:siren-fill" className="text-gray-500 hor-menu-icon" />
                </span>
                Interventions
            </NavLink>

            {has_role(['arm']) && (
                <>
                    <NavLink to={INTERNAL_ROUTES.TEAMS} className={({ isActive }) => isActive ? "custom-link-active" : "custom-link"}>
                        <span className="mr-2">
                            <Icon icon="mdi:doctor" className="text-gray-500 hor-menu-icon" />
                        </span>
                        Equipes
                    </NavLink>
                </>
            )}

            {/* <NavLink to={INTERNAL_ROUTES.PATIENTS} className={({ isActive }) => isActive ? "custom-link-active" : "custom-link"}>
                <span className="mr-2">
                    <Icon icon="mdi:patient" className="text-gray-500 hor-menu-icon" />
                </span>
                Patients
            </NavLink> */}

            {/* {has_role(['medical-regulator']) && (
                <>
                    <NavLink to={INTERNAL_ROUTES.LIBRARY} className={({ isActive }) => isActive ? "custom-link-active" : "custom-link"}>
                        <span className="mr-2">
                            <Icon icon="solar:library-bold-duotone" className="text-gray-500 hor-menu-icon" />
                        </span>
                        Bibliothèque
                    </NavLink>
                </>
            )} */}
        </div>
    )
}

const StaffNavbar = () => {
    const { user } = useContext(UserContext);
    return (
        <nav role="navigation" className="bg-white shadow xl:block hidden sticky top-0 right-0 z-20">
            <div className="mx-auto container px-6 py-2 xl:py-0">
                <div className="flex items-center justify-between">
                    {/* Bar mobile */}
                    <div className="inset-y-0 left-0 flex items-center xl:hidden">
                        <div className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-100 focus:outline-none transition duration-150 ease-in-out">
                            <div className="visible xl:hidden">
                                <Icon id="open-m-menu" icon="gravity-ui:bars" className="xl:hidden" />
                            </div>
                            <div id="close-m-menu" className="hidden close-m-menu text-gray-700">
                                <Icon icon="gravity-ui:xmark" />
                            </div>
                        </div>
                    </div>

                    {/* NavBar Menu */}
                    <button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-md flex w-full sm:w-auto items-center sm:items-stretch justify-end sm:justify-start">
                        <div className="flex items-center">
                            <NavLink to={INTERNAL_ROUTES.STAFF_DASHBOARD} className="flex items-center justify-between mr-4">
                                <img className="w-8 sm:w-10 md:w-12" src={logo} alt="Logo SAMU BENIN" />
                                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ml-2">SAMU BENIN</span>
                            </NavLink>
                        </div>
                    </button>
                    <NavBarItems />
                    <div className="flex justify-end items-center">
                        <UserMenuNotification roles={user.groups} />
                        <UserMenuDropdown email={user.email} first_name={user.first_name} last_name={user.last_name} />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default StaffNavbar