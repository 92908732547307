import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Icon } from '@iconify/react';
import { getItemFieldFormatter } from './listView.js';
import { useNavigate } from 'react-router-dom';

const CrudListViewTable = ({
  data,
  actions,
  fields,
  setSelectedItem,
  setShowFormModal,
  setShowDeleteConfirmation,
  actionsGetters,
  routeActions,
  customActions,
  hideActions
}) => {

  const navigate = useNavigate()
  return (
    <DataTable
      value={data}
      showGridlines={true}
      sortMode="multiple"
      removableSort
      rows={5}
      rowsPerPageOptions={[5, 10, 25, 50]}
      tableStyle={{ minWidth: '50rem' }}
    >
      {fields.map((field) => (
        <Column
          key={field.name}
          field={field.name}
          header={(field.label || field.name).toUpperCase()}
          body={getItemFieldFormatter(field)}
          {...field.props}
        />
      ))}
      {!hideActions && (
        <Column
          field="action"
          header="Actions"
          body={(item) => (
            <div className="flex justify-center gap-10">
              {routeActions?.length && routeActions.map(action => (
                <a
                  style={{cursor: "pointer"}}
                  className='mb-2'
                  onClick={() => {navigate(action.route(item.id))
                }}>{action.label}</a>
              ))}
              {customActions?.length && customActions.map(action => (
                <a
                  style={{cursor: "pointer"}}
                  onClick={() => action.onClick(item)}>{action.label}</a>
              ))}
              {/* {actionsGetters.map(action => (
                <Icon
                  key={`${action.name} - ${item.id}`}
                  onClick={action.onClick(item)}
                  className={`Zpx-2 py-2 pr-4`}
                  label={action.label}
                  icon={action.icon}
                  outlined
                />
              ))} */}
              {actions.update && (
                <Icon
                  icon={'eva:edit-2-fill'}
                  style={{cursor: "pointer"}}
                  onClick={() => { setSelectedItem(item); setShowFormModal(true) }}
                  className="xl:text-lg text-blue-500 cursor-pointer hover:-translate-y-1 mt-1"
                />
              )}
              {actions.delete && (
                <Icon
                  icon={'eva:trash-2-outline'}
                  style={{cursor: "pointer"}}
                  onClick={() => { setSelectedItem(item); setShowDeleteConfirmation(true) }}
                  className="xl:text-lg text-red-500 cursor-pointer hover:-translate-y-1 mt-1"
                />
              )}
            </div>
          )}
        />
      )}
    </DataTable>
  );
};

export default CrudListViewTable;
