import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import generatePDF, { Resolution, Margin, usePDF } from 'react-to-pdf';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import FormField from "../../components/forms/field";
import { FieldsGroupDivider } from "../arm/InterventionFormEditor";
import useInterventionFormFields from "../arm/useInterventionFormFields";
import { Dialog } from "primereact/dialog";
import BooleanInput from "../../components/Utils/BooleanInput";
import { ProgressSpinner } from "primereact/progressspinner";
import logo from '../../assets/images/logo.png';


const PDF_OPTIONS = {
  page: {
     // margin is in MM, default is Margin.NONE = 0
     margin: 22,
     // default is 'A4'
     format: 'letter',
     // default is 'portrait'
  },
};

const MedicalRecordDetail = ({
  data,
  visible,
  setVisible,
  name="Dossier médical"
}) => {

  const { toPDF, targetRef } = usePDF({filename: `samu-dossier-medical-${data.id}.pdf`});
  const { id } = useParams()
  const {
    stateIsReady,
    interventionReasons,
    numbersFields,
    fieldsGroups: {
      baseInfoFields,
      patientPersonalInfoFields,
      patientHemodynamicFields,
      patientHealthFields,
      interventionLocationFields,
      closeRelativeFields,
      patientTreatmentsFields,
      patientVitalParamsFields,
      patientMedicalGesturesFields,
      relatedDoctorFields,
      patientHospitalizationFields,
      patientLifeForecastJ1Fields,
      patientLifeForecastJ5Fields,
      patientLifeForecastJ10Fields
    }
  } = useInterventionFormFields({data, intervention: {}})

  const getFieldProps = (field) => {
    let _props = { ...field, disabled: true }
    if (field.Component === BooleanInput) {
      _props.checked = data[field.key]
    } else {
      _props.value = data[field.key]
    }

    return _props
  }

  const getPdfElement = () => document.getElementById('pdf-container');

  if (!stateIsReady) return <ProgressSpinner />

  return (
    <Dialog
      visible={visible}
      onHide={() => setVisible(false)}
      // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
    >
      <>
        <Button
          // onClick={() => toPDF()}
          onClick={() => generatePDF(getPdfElement, PDF_OPTIONS)}
          className={`rounded bg-primary text-white px-2 py-2 pr-4 mb-2`}
          label={"Télécharger en PDF"}
          outlined
        />
        <hr />
        <div ref={targetRef} id="pdf-container" className="pt-5">
          <div className="flex justify-between mb-2">
            <div className="flex justify-left">
              <img className="w-5 sm:w-8 md:w-10" src={logo} alt="Logo SAMU BENIN" />
              <div className="flex items-center ml-2">
                <p className="lg:text-xl font-semibold">
                  Samu Bénin
                </p>
              </div>
            </div>
            <p className="lg:text-xl font-semibold p-2">
              {name} <strong className="text-primary">N°{data.id}</strong>
            </p>
          </div>

          {/** First part */}
          <Card>
              <FieldsGroupDivider name="Informations génériques" />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {[...baseInfoFields, ...patientPersonalInfoFields].map(field => (
                  <FormField {...getFieldProps(field)} />
                ))}
              </div>

              <FieldsGroupDivider name="Hémodynamique" />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {patientHemodynamicFields.map(field => (
                  <FormField {...getFieldProps(field)} />
                ))}
              </div>

              <FieldsGroupDivider name="Etat de santé du patient" />
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                {patientHealthFields.map(field => (
                  <FormField {...getFieldProps(field)} />
                ))}
              </div>

              <FieldsGroupDivider name="Autres" />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                {[...interventionLocationFields, ...closeRelativeFields].map(field => (
                  <FormField {...getFieldProps(field)} />
                ))}
              </div>
            </Card>

          {/** Second part */}
          <Card>
            <FieldsGroupDivider name="Soins éffectues" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {patientTreatmentsFields.filter(f => 
                !["recovery_of_intervention_costs"].includes(f.key)
              ).map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Paramètres vitaux" />
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">
              {patientVitalParamsFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Gestes Techniques SAMU" />
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
              {patientMedicalGesturesFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Par le médecin" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {relatedDoctorFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Hospitalisation" />
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
              {patientHospitalizationFields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J1" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ1Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J5" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ5Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>

            <FieldsGroupDivider name="Devenir du patient à J10" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              {patientLifeForecastJ10Fields.map(field => (
                <FormField {...getFieldProps(field)} />
              ))}
            </div>
          </Card>
        </div>
      </>
    </Dialog>
  )
}

export default MedicalRecordDetail
