import axios from "axios";
import Cookies from 'js-cookie';

axios.interceptors.request.use(
    async (config) => {
        const access = Cookies.get('access');
        if (access) {
            config.headers["Authorization"] = "JWT " + access;
        }
        return config;
    },
    (error) => {
        console.log("-----msg: ", error)
        return Promise.reject(error);
    }
);


export default axios