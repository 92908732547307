import LoadingButton from '../../Utils/LoadingButton';
import { MultiSelect } from 'primereact/multiselect';

const NewMemberPlanning = ({ on_submit, loading, selected_members, setSelectedMembers, members }) => {
    const option_template = (option) => {
        return (
            <div>
                {option.name}
            </div>
        );
    };
    const footer_template = () => {
        const length = selected_members ? selected_members.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> personne{length > 1 ? 's' : ''} sélectionnée{length > 1 ? 's' : ''}
            </div>
        );
    };


    return (
        <form className="flex w-full flex-col gap-4" onSubmit={on_submit}>
            <div className="flex justify-center mb-4">
                <MultiSelect onChange={(e) => setSelectedMembers(e.value)}
                    value={selected_members}
                    options={members}
                    optionLabel="name"
                    optionValue="id"
                    display="chip"
                    itemTemplate={option_template}
                    panelFooterTemplate={footer_template}
                    placeholder="Sélectionner le personnel médical"
                    className="w-1/2 border border-gray-300 focus:ring-primary focus:border-primary" />
            </div>

            <LoadingButton label="Valider" loading={loading} />
        </form>
    )
}


export default NewMemberPlanning