export const getLastNumberInText = (text) => {
  text = String(text);
  const matches = text.match(/\d+/g);
  if (!matches) {
    return "";
  }
  return matches[matches.length - 1];
}

export const capitalizeStr = function (string) {
  if (!string?.length) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}


export const toQueryString = (params) => {
  return (
    "?" +
    Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&")
  );
}
