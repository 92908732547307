import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { getItemFieldFormatter } from './listView.js';
import { capitalizeStr } from '../../utils/function.js';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';


export const DefaultCrudCardComponent = ({
  item,
  resource,
  actions,
  fields,
  cardSkipFields = [],
  setSelectedItem,
  setShowFormModal,
  setShowDeleteConfirmation,
  actionsGetters,
  // TODO: factorize in ListView component
  routeActions,
  onDetailClick = null
}) => {
  // TODO: factorize in ListView component
  const navigate = useNavigate()
  return (
    <Card
      key={item.id}
      title={resource.getTitle(item)}
      subTitle={resource.getSubTitle && resource.getSubTitle(item)}
      header={resource.imageField && (
        <img alt="Card" src={item[resource.imageField]} />
      )}
      style={{cursor: onDetailClick ? "pointer" : ""}}
      onClick={() => onDetailClick ? onDetailClick(item) : null}
      footer={(
        <div className="flex justify-between gap-5">
          {/* {actionsGetters.map(action => (
            <Button
              onClick={action.onClick(item)}
              className={`rounded bg-primary text-white px-2 py-2 pr-4`}
              label={action.label}
              icon={action.icon}
              outlined
            />
          ))} */}
          {actions.update && (
            <Button
              onClick={() => { setSelectedItem(item); setShowFormModal(true) }}
              // className={`rounded bg-primary text-white px-2 py-2 pr-4`}
              label={"Modifier"}
              icon={'eva:edit-2-fill'}
              outlined
            />
          )}
          {actions.delete && (
            <Button
              onClick={() => { setSelectedItem(item); setShowDeleteConfirmation(true) }}
              // className={`rounded bg-red-500 text-white px-2 py-2 pr-4`}
              label={"Supprimer"}
              icon={'eva:trash-2-outline'}
              severity="danger"
              outlined
            />
          )}
          {routeActions?.length && routeActions.map(action => (
            // TODO: factorize with listViewTable in listView
            <a
              style={{cursor: "pointer"}}
              onClick={() => {
              console.log("+++++++++", action.route(item.id))
              navigate(action.route(item.id))
            }}>{action.label}</a>
          ))}
        </div>
      )}
      className="md:w-25rem"
    >
      {fields.filter(f => !cardSkipFields.includes(f.name)).map(field => (
        <div className='flex' key={`${field.name}-${item.id}`}>
          {capitalizeStr(field.label || field.name)}: {" "} 
          <span className='ml-4'>{getItemFieldFormatter(field)(item)}</span>
        </div>
      ))}
    </Card>
  )
}
