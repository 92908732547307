import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import useNotificationSocket from '../common/hooks/useNotificationSocket';
import { Groups, NotificationRequestType, NotificationType } from '../common/constants';
import { get_medical_members, getMembersOptions } from '../../services/management/users.service';
import { Button } from 'primereact/button';
import { Toast } from 'flowbite-react';
import { Sentry } from '../..';


const InterventionAskMedRegHelpModal = ({ interventionId, interventionFromId, visible, setVisible, onSend }) => {
  const [staffMembers, setStaffMembers] = useState([])
  const [selectedMedReg, setSelectedMedReg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [notification, notificationSocket] = useNotificationSocket();

  const sendInterventionToMedReg = () => {
    notificationSocket.send(
      JSON.stringify({
        "notif_type": NotificationType.REQUEST.value,
        "request_type": NotificationRequestType.MED_REG_HELP_IN_INTERVENTION.value,
        "receiver": selectedMedReg.id,
        "target_id": interventionId
      })
    );
    onSend()
    setVisible(false)
  }

  useEffect(() => {
    const setStaffMembersData = async () => {
      setLoading(true);
      try {
        // FIXME: get all, and auto-complete + pagination
        const response = await get_medical_members(100, 0, {
          available: true,
          "groups__name": Groups.MEDICAL_REGULATOR.value
        });
        const availableMembers = getMembersOptions(response.results)
        setStaffMembers(availableMembers)
        setLoading(false);
      } catch (e) {
        Sentry.captureMessage(`Error ${e}`, "error");
        setLoading(false);
      }
    };
    setStaffMembersData();
  }, []);

  return (
    <>
      <Dialog
        header="Choisir un Médecin régulateur parmis ceux disponibles"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => {
          setSelectedMedReg(null);
          setVisible(false);
        }}
      >
        <Dropdown
          value={selectedMedReg}
          disabled={loading}
          onChange={(e) => setSelectedMedReg(e.value)}
          options={staffMembers}
          optionLabel="display"
          placeholder="Choisir un Médecin régulateur"
          className="w-full md:w-14rem"
        />
        <Button
          className="bg-primary text-white px-4 py-2 mt-5"
          label="Valider"
          onClick={sendInterventionToMedReg}
          loading={loading}
          disabled={loading || !selectedMedReg}
        />
      </Dialog>
    </>
  )
};

export default InterventionAskMedRegHelpModal;
