import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Icon } from '@iconify/react';
import ConfirmModal from "../modals/ConfirmModal";
import { useEffect, useState } from "react";
import FormModal from "../modals/FormModal";
import UpdatePlanning from "../forms/planning/UpdatePlanning";
import NewMemberPlanning from "../forms/planning/NewMemberPlanning";

import DefaultAvatar from "../../assets/images/default-user.jpg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import { TabPanel, TabView } from "primereact/tabview";

const PlanningDetail = ({ dialogVisible, setDialogVisible, selectedEvent, remove_member, add_member, delete_planning, update_planning, all_members }) => {

    const get_available_members = (all_members, data, role) => {
        const members_on_guard_ids = Object.values(data.members_on_guard)
            .flat()
            .map(member => member.id);

        const members_permanent_ids = Object.values(data.members_permanent)
            .flat()
            .map(member => member.id);

        const assigned_member_ids = new Set([...members_on_guard_ids, ...members_permanent_ids]);

        const available_members = all_members.filter(member => {
            return !assigned_member_ids.has(member.id) &&
                member.groups.some(group => group.name === role);
        });

        const result = [];
        available_members.forEach(member => {
            result.push({
                id: member.id,
                name: `${member.first_name} ${member.last_name}`,
            })
        });

        return result;
    };


    const [update_visible, setUpdateVisible] = useState(false);
    const [add_visible, setAddVisible] = useState(false);
    const [remove_visible, setRemoveVisible] = useState(false);
    const [delete_visible, setDeleteVisible] = useState(false);

    const [add_btn_clicked, setAddBtnClicked] = useState(false);

    const [btn_loading, setBtnLoading] = useState(false);
    const [update_dates, setUpdateDates] = useState([]);
    const [member_to_remove, setMemberToRemove] = useState(null);
    const [selected_members, setSelectedMembers] = useState([]);
    const [selected_role, setSelectedRole] = useState("arm");
    const [selected_mode, setSelectedMode] = useState(null);
    const [available_members, setAvailableMembers] = useState([]);


    useEffect(() => {
        if (add_btn_clicked) {
            preview_member_add();
            setAddBtnClicked(false);
        }
    }, [selected_role, add_btn_clicked]);

    const handle_update_planning = (event) => {
        event.preventDefault();
        setBtnLoading(true);
        update_planning(selectedEvent.id, update_dates);
        setBtnLoading(false);
        setUpdateVisible(false);
    }

    const handle_delete_planning = () => {
        setBtnLoading(true);
        delete_planning(selectedEvent.id);
        setDeleteVisible(false);
        setBtnLoading(false);
    }

    const handle_add_member = (event) => {
        event.preventDefault();
        setBtnLoading(true);
        add_member(selectedEvent.id, selected_members, selected_mode);
        setSelectedMembers([]);
        setBtnLoading(false);
        setAddVisible(false);
    }

    const handle_remove_member = () => {
        setBtnLoading(true);
        remove_member(member_to_remove);
        setRemoveVisible(false);
        setBtnLoading(false);
        setMemberToRemove(null);
    }


    // Modal display
    const preview_planning_update = () => {
        const end_date = new Date(selectedEvent.end);
        end_date.setDate(end_date.getDate() - 1);

        setUpdateDates([selectedEvent.start, end_date]);
        setUpdateVisible(true);
    }
    const preview_member_add = () => {
        const available_members = get_available_members(all_members, selectedEvent.extendedProps, selected_role);
        setAvailableMembers(available_members);
        setAddVisible(true);
    }
    const preview_planning_deletion = () => {
        setDeleteVisible(true);
    }
    const preview_member_deletion = (planning_member_id) => {
        setRemoveVisible(true);
        setMemberToRemove(planning_member_id);
    }

    const cancel_member_deletion = () => {
        setMemberToRemove(null);
        setRemoveVisible(false);
    }
    const cancel_planning_deletion = () => {
        setDeleteVisible(false);
    }

    const slug_roles_fr = {
        "admin": "Directeur général",
        "arm": "ARM",
        "company-director": "Directeur de société",
        "branch-director": "Directeur de branche",
        "chief-supervisor": "Surveillant",
        "branch-supervisor": "Superviseur de branche",
        "medical-regulator": "Med Reg",
        "ambulance-chief": "Chef d'ambulance",
        "ambulance-member": "Ambulancier",
        "ambulance-driver": "Chauffeur",
        "emergency-nurse": "Infirmière",
        "rescuer": "Secouriste",
        "permanent-staff": "Personnel permanant",
    };

    const name_template = (member) => {
        return (
            <div className="flex items-center gap-2">
                <img alt={member.name} src={DefaultAvatar} width="32" />
                <span>{member.name}</span>
            </div>
        )
    }

    const action_template = (member) => {
        return (
            <div className="flex w-full justify-center flex-wrap items-center gap-4">
                <button onClick={() => preview_member_deletion(member.planning_member_id)} className="px-2 py-1 bg-red-500 text-white shadow rounded"><Icon icon={"ic:baseline-delete"} /></button>
            </div>
        )
    }

    const modal_header = (text) => {
        return <p className="lg:text-xl font-semibold p-2">{text}</p>
    }

    return (

        <>

            <FormModal header={modal_header('Mettre à jour une planification')} children={<UpdatePlanning on_submit={handle_update_planning} loading={btn_loading} dates={update_dates} setDates={setUpdateDates} />} visible={update_visible} setVisible={setUpdateVisible} />
            <FormModal header={modal_header('Ajouter un membre à l\'équipe')} children={<NewMemberPlanning on_submit={handle_add_member} loading={btn_loading} selected_members={selected_members} setSelectedMembers={setSelectedMembers} members={available_members} />} visible={add_visible} setVisible={setAddVisible} />
            <ConfirmModal visible={remove_visible} setVisible={setRemoveVisible} title="Retirer un membre de l'équipe" content="Voulez-vous vraiment retirer ce membre ?" icon="healthicons:warning" accept={() => { handle_remove_member() }} reject={() => { cancel_member_deletion() }} />
            <ConfirmModal visible={delete_visible} setVisible={setDeleteVisible} title="Supprimer cette planification" content="Voulez-vous vraiment supprimer cette planification ?" icon="healthicons:warning" accept={() => { handle_delete_planning() }} reject={() => { cancel_planning_deletion() }} />

            <Dialog
                header="Détails de la plannification"
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                style={{ width: '70vw' }}
            >
                {selectedEvent && (

                    <div>
                        <div className="flex justify-between items-center gap-4 my-2 py-2">
                            <h2 className="text-xl font-semibold mb-2">{selectedEvent.title}</h2>
                            <div className="flex justify-end items-center gap-2">
                                <button onClick={() => preview_planning_deletion()} className="px-2 py-1 bg-transaprent hover:bg-red-500 border border-red-500 rounded text-red-500 hover:text-white shadow">Supprimer</button>
                                <button onClick={() => preview_planning_update()} className="px-2 py-1 bg-transparent hover:bg-secondary border border-secondary text-secondary hover:text-white shadow rounded">Modifier</button>

                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center gap-x-8 gap-y-4 my-4 flex-wrap">
                            <div className="font-semibold text-lg">{selectedEvent.extendedProps.start_date_fr}</div>
                            <div className="text-xl text-gray-500"><Icon icon={"guidance:left-arrow"} /></div>
                            <div className="font-semibold text-lg">{selectedEvent.extendedProps.end_date_fr}</div>
                        </div>
                        <div className="card my-4">
                            <Accordion multiple>
                                <AccordionTab
                                    onClick={() => setSelectedMode('permanent')}
                                    header={
                                        <span className="flex items-center gap-2 w-full">
                                            <h2>Personnel médical de permanence</h2>
                                            <Badge value={selectedEvent.extendedProps.permanent_count} className="ml-auto" />
                                        </span>
                                    }
                                >
                                    <TabView scrollable>
                                        {Object.entries(selectedEvent.extendedProps.members_permanent).map(([role, members_list]) => {
                                            return (
                                                <TabPanel key={role} header={slug_roles_fr[role]} onClick={() => setSelectedRole(role)}>
                                                    <div className="flex justify-end items-center">
                                                        <button onClick={() => setAddBtnClicked(true)} className="p-1 bg-secondary text-white shadow rounded"><Icon icon={"ic:baseline-add"} /></button>
                                                    </div>
                                                    <DataTable value={members_list} showGridlines={false} >
                                                        <Column header="Nom & prénom(s)" body={name_template} style={{ width: '90%' }}></Column>
                                                        <Column header="Action" body={action_template} style={{ width: '10%' }}></Column>
                                                    </DataTable>
                                                </TabPanel>
                                            );
                                        })}
                                    </TabView>

                                </AccordionTab>

                                <AccordionTab
                                    onClick={() => setSelectedMode('on_guard')}
                                    header={
                                        <span className="flex items-center gap-2 w-full">
                                            <h2>Personnel médical de garde</h2>
                                            <Badge value={selectedEvent.extendedProps.on_guard_count} className="ml-auto" />
                                        </span>
                                    }
                                >
                                    <TabView scrollable>
                                        {Object.entries(selectedEvent.extendedProps.members_on_guard).map(([role, members_list]) => {
                                            return (
                                                <TabPanel key={role} header={slug_roles_fr[role]} onClick={() => setSelectedRole(role)}>
                                                    <div className="flex justify-end items-center">
                                                        <button onClick={() => setAddBtnClicked(true)} className="p-1 bg-secondary text-white shadow rounded"><Icon icon={"ic:baseline-add"} /></button>
                                                    </div>
                                                    <DataTable value={members_list} showGridlines={false} >
                                                        <Column header="Nom & prénom(s)" body={name_template} style={{ width: '90%' }}></Column>
                                                        <Column header="Action" body={action_template} style={{ width: '10%' }}></Column>
                                                    </DataTable>
                                                </TabPanel>
                                            );
                                        })}
                                    </TabView>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default PlanningDetail;