import React from 'react';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';
import CrudList from '../../components/crud/list.js';



const AmbulancesList = () => {

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "ambulances",
        label: "ambulance",
        labelPlural: "ambulances",
        pronoun: "une",
        pronounChange: "cette",
        icon: "mdi:ambulance",
        getTitle: (item) => item.identifier,
        imageField: null
      }}
      fields={[
        {
          name: "identifier",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Identifiant",
          labelPlural: "",
        },
        {
          name: "ambulance_type",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "ambulance_types",
            getLabel: (item) => item.name
          },
          required: true,
          label: "Type",
          labelPlural: "Types",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.STRING,
          label: "",
          labelPlural: "",
        },
        {
          name: "status",
          type: CRUD_FIELD_TYPE.ENUM,
          choices: [
            {
              value: "available",
              label: "Disponible",
              severity: "success"
            },
            {
              value: "spoiled",
              label: "Indisponible",
              severity: "warning"
            },
          ],
          required: true,
          label: "status",
          labelPlural: "status",
        },
        {
          name: "company",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "companies",
            getLabel: (company) => company.name
          },
          required: true,
          label: "Antenne",
          labelPlural: "Antennes",
        },
      ]}
      actions={{ create: true, update: true, delete: true}}
      defaultPageSize={10}
    />
  )
}

export default AmbulancesList;
