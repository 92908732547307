import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Icon } from '@iconify/react';
import 'primereact/resources/primereact.min.css';
import { INTERNAL_ROUTES } from '../../utils/internal_routes';
import { Card } from 'primereact/card';
import Heading from '../../components/Utils/Heading';


const Settings = () => {

  return (
    <div className="p-4 lg:p-8">
      <Heading title={"Paramètrage de l'application"} />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-3 gap-4">
        <Card title="Interventions">
          <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.INTERVENTION_REASONS}`} className={"custom-link-sidebar group"}>
            <Icon icon="bxs:ambulance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
            <span className="ml-4">Motifs d'intervention</span>
          </NavLink>
        </Card>
        <Card title="Ambulances">
          <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.AMBULANCES_TYPES}`} className={"custom-link-sidebar group"}>
            <Icon icon="bxs:ambulance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
            <span className="ml-4">Types ambulances</span>
          </NavLink>
          <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.AMBULANCES_QUALIFICATIONS}`} className={"custom-link-sidebar group"}>
            <Icon icon="bxs:ambulance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
            <span className="ml-4">Critères ambulances</span>
          </NavLink>
        </Card>
        {/* <Card title="Suivi de performance des équipes">
          <NavLink to={`${INTERNAL_ROUTES.ADMIN_BACKOFFICE}/${INTERNAL_ROUTES.MONITORING_PARAMS}`} className={"custom-link-sidebar group"}>
            <Icon icon="bxs:ambulance" className="xl:text-lg text-gray-500 transition duration-75 menu-icon group-hover:text-gray-900" />
            <span className="ml-4">Suivi de performance des équipes</span>
          </NavLink>
        </Card> */}
      </div>
  </div>)
}

export default Settings;
