import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const InterventionTeamMonitoring = ({
  staticFilters = {},
  routeActions={}
}) => {

  const { intervention_team_id } = useParams()

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "intervention_ambulance_monitoring_params",
        label: "performance de l'équipe",
        labelPlural: "performances",
        pronoun: "une",
        pronounChange: "cette",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "monitoring_param",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "monitoring_params",
            getLabel: (item) => item.name
          },
          required: false,
          label: "Paramètre",
          labelPlural: "Paramètres",
        },
        {
          name: "selected_choice",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "monitoring_params_choices",
            getLabel: (item) => item.name
          },
          required: true,
          label: "Performance",
          labelPlural: "Performances",
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.TEXT,
        },
      ]}
      staticFilters={{intervention_ambulance: intervention_team_id}}
      staticFormData={{intervention_ambulance: intervention_team_id}}
      defaultPageSize={10}
    />
  )
}

export default InterventionTeamMonitoring;
