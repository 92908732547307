import React, { useEffect, useRef, useState } from 'react';
import { DefaultCrudCardComponent } from './listViewCardDefault';


const CrudListViewCard = ({
  data,
  resource,
  actions,
  fields,
  cardSkipFields = [],
  setSelectedItem,
  setShowFormModal,
  setShowDeleteConfirmation,
  actionsGetters,
  // TODO: factorize in ListView component
  routeActions,
  CardComponent=DefaultCrudCardComponent,
  onDetailClick
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
    {data.map((item) => (
      <CardComponent
        {...{
          item,
          resource,
          actions,
          fields,
          cardSkipFields,
          setSelectedItem,
          setShowFormModal,
          setShowDeleteConfirmation,
          actionsGetters,
          routeActions,
          onDetailClick
        }}
      />
    ))}
  </div>
);

export default CrudListViewCard;
