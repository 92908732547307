import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';

import CrudList from '../../components/crud/list.js';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';


const ClientServiceRatings = ({
  staticFilters = {},
  routeActions={}
}) => {

  const { service_id } = useParams()

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.TABLE}
      resource={{
        endpointPrefix: "client_services_ratings",
        label: "Notation du service",
        labelPlural: "choix",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:office-building-marker-outline",
        getTitle: (item) => item.name,
        imageField: null
      }}
      fields={[
        {
          name: "date",
          type: CRUD_FIELD_TYPE.DATE,
          required: true,
          label: "date",
          labelPlural: "",
        },
        {
          name: "score",
          type: CRUD_FIELD_TYPE.NUMBER,
          required: true,
        },
        {
          name: "description",
          type: CRUD_FIELD_TYPE.TEXT,
        },
      ]}
      staticFilters={{client_service: service_id}}
      staticFormData={{client_service: service_id}}
      defaultPageSize={10}
    />
  )
}

export default ClientServiceRatings;
