import React, { useContext, useRef, useState } from 'react';
import { login } from '../services/auth.service';

import loginHero from '../assets/images/login-hero.png';
import LoginFormHeader from '../components/login/LoginFormHeader';
import LoginForm from '../components/forms/LoginForm';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../utils/internal_routes';
import { Toast } from 'primereact/toast';

const Login = () => {
    const { updateUser } = useContext(UserContext)

    const [credentials, setCredentials] = useState({ username: '', password: '', remember_me: false });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const password_field_ref = useRef();
    const toast = useRef(null);

    const handle_change = (e) => {
        const { name, value } = e.target;
        if (name === 'username' || name === 'password') {
            setCredentials((prev_credentials) => ({
                ...prev_credentials,
                [name]: value,
            }));
        }
        if (name === 'remember_me') {
            setCredentials((prev_credentials) => ({
                ...prev_credentials,
                [name]: e.target.checked,
            }));
        }
    };

    const handle_submit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const current_user = await login(credentials);
            if (!current_user) {
                handle_notification('error', 'Erreur de connexion', 'Nom d\'utilisateur ou mot de passe incorrect');
                setLoading(false);
            } else {
                updateUser(current_user);

                const path_for_roles = {
                    "admin": INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.ADMIN_DASHBOARD,
                    "branch-director": INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.ADMIN_DASHBOARD,
                    "branch-supervisor": INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.ADMIN_DASHBOARD,
                    "chief-supervisor": INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.ADMIN_DASHBOARD,
                    "company-director": INTERNAL_ROUTES.ADMIN_BACKOFFICE + "/" + INTERNAL_ROUTES.ADMIN_DASHBOARD,
                    "ambulance-chief": INTERNAL_ROUTES.STAFF_BACKOFFICE + "/" + INTERNAL_ROUTES.STAFF_DASHBOARD,
                    "ambulance-member": INTERNAL_ROUTES.STAFF_BACKOFFICE + "/" + INTERNAL_ROUTES.STAFF_DASHBOARD,
                    "ambulance-driver": INTERNAL_ROUTES.STAFF_BACKOFFICE + "/" + INTERNAL_ROUTES.STAFF_DASHBOARD,
                    "arm": INTERNAL_ROUTES.STAFF_BACKOFFICE + "/" + INTERNAL_ROUTES.STAFF_DASHBOARD,
                    "medical-regulator": INTERNAL_ROUTES.STAFF_BACKOFFICE + "/" + INTERNAL_ROUTES.STAFF_DASHBOARD,
                    "patient": INTERNAL_ROUTES.PATIENT_DASHBOARD,
                };

                let redirect_path = INTERNAL_ROUTES.FORBIDDEN;

                for (const group of current_user.groups) {
                    if (path_for_roles[group.name]) {
                        redirect_path = path_for_roles[group.name];
                        break;
                    }
                }

                setLoading(false);
                navigate(redirect_path);
            }

        } catch (error) {
            // reset_password();
            setLoading(false);
            if (error.response) {
                let message = error.response.data.detail ? error.response.data.detail : "Identifiant ou mot de passe incorrect";
                handle_notification('error', 'Erreur', message);
            } else {
                handle_notification('error', 'Erreur', 'Une erreur est survenue. Veuillez réessayer');
            }
        }
    };

    const handle_notification = (severity, summary, detail) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail, life: 5000 });
    }

    const reset_password = () => {
        setCredentials(prev_credentials => ({
            ...prev_credentials,
            password: ''
        }));

        if (password_field_ref.current) {
            password_field_ref.current.value = '';
        }
    };

    return (
        <>
            <Toast ref={toast} />

            <div className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5">
                <div className="bg-gray-50 text-gray-500 rounded-lg shadow-xl w-full overflow-hidden max-w-7xl">
                    <div className="md:flex w-full">
                        <div className="hidden md:flex w-1/2 bg-white py-10 px-10 md:items-center justify-center">
                            <img src={loginHero} alt="Illustration Analyse de données" />
                        </div>
                        <div className="w-full md:w-1/2 p-5 md:px-10">
                            <LoginFormHeader />
                            <LoginForm
                                handle_submit={handle_submit}
                                handle_change={handle_change}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
