import React, { useEffect, useRef, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import Heading from '../../../components/Utils/Heading';
import FormModal from '../../../components/modals/FormModal';
import { Icon } from '@iconify/react';
import DateFilter from '../../../components/forms/DateFilter';

const InterventionStat = () => {
    const [interventionData, setInterventionData] = useState([]);
    const [dataByHour, setDataByHour] = useState([]);
    const [dataByDay, setDataByDay] = useState([]);

    const [filter_start_date, setFilterStartDate] = useState(null);
    const [filter_end_date, setFilterEndDate] = useState(null);

    const [filter_visible, setFilterVisible] = useState(false);
    const [btn_loading, setBtnLoading] = useState(false);


    const toast = useRef(null)
    useEffect(() => {
        // Simuler l'appel API avec des données statiques
        setTimeout(() => {
            setInterventionData([
                { name: 'Accidents', valeur: 400 },
                { name: 'Crises cardiaques', valeur: 300 },
                { name: 'Incidents domestiques', valeur: 300 },
                { name: 'Autres', valeur: 200 }
            ]);
            setDataByHour([
                { hour: '00:00', interventions: 5 },
                { hour: '01:00', interventions: 3 },
                { hour: '02:00', interventions: 4 },
                { hour: '03:00', interventions: 2 },
                { hour: '04:00', interventions: 6 },
                { hour: '05:00', interventions: 7 },
                { hour: '06:00', interventions: 8 },
                { hour: '07:00', interventions: 9 },
                { hour: '08:00', interventions: 0 },
                { hour: '09:00', interventions: 21 },
                { hour: '10:00', interventions: 2 },
                { hour: '11:00', interventions: 1 },
                { hour: '12:00', interventions: 74 },
                { hour: '13:00', interventions: 55 },
                { hour: '14:00', interventions: 86 },
                { hour: '15:00', interventions: 7 },
                { hour: '16:00', interventions: 18 },
                { hour: '17:00', interventions: 9 },
                { hour: '18:00', interventions: 20 },
                { hour: '19:00', interventions: 111 },
                { hour: '20:00', interventions: 202 },
                { hour: '21:00', interventions: 25 },
                { hour: '22:00', interventions: 29 },
                { hour: '23:00', interventions: 7 }
            ]);
        }, 1000);

        setDataByDay([
            { day: 'Lundi', interventions: 50 },
            { day: 'Mardi', interventions: 45 },
            { day: 'Mercredi', interventions: 60 },
            { day: 'Jeudi', interventions: 55 },
            { day: 'Vendredi', interventions: 70 },
            { day: 'Samedi', interventions: 65 },
            { day: 'Dimanche', interventions: 40 }
        ]);
    }, []);

    const get_period_stats = async (event) => {
        event.preventDefault();
        setBtnLoading(true);
        try {
            alert("En cours d'implémentation");
            const param = {
                start_date: filter_start_date,
                end_date: filter_end_date
            }
            setFilterVisible(false);
            setBtnLoading(false);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Statistiques récupérées avec succès.', life: 5000 });
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: e.message || "Une erreur est survenue.", life: 5000 });
        }
    }

    const show_filter_modal = () => {
        setFilterVisible(true);
    };


    return (
        <>
            <Toast ref={toast} />
            <Heading title="Graphiques liés aux interventions" />
            <FormModal header={"Filtrer"} visible={filter_visible} setVisible={setFilterVisible} children={<DateFilter on_submit={get_period_stats} loading={btn_loading} set_start_date={setFilterStartDate} set_end_date={setFilterEndDate} type="interventions" />} />

            <div className="w-full flex justify-end items-center px-4 md:px-8">
                <button onClick={show_filter_modal} className="flex gap-2 justify-center items-center px-4 py-1.5 bg-black hover:bg-primary rounded text-white"><Icon icon={'fa:filter'} className="text-xl" /> <span>Filtrer</span></button>
            </div>
            <div className="flex justify-center px-4 my-8 items-center w-full gap-16 flex-wrap">
                <div className="w-full lg:w-5/12">
                    <Card title="Répartition des Interventions par Type">
                        <BarChart width={500} height={300} data={interventionData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="valeur" fill="#8884d8" />
                        </BarChart>
                    </Card>
                </div>

                <div className="w-full lg:w-5/12">
                    <Card title="Fréquence des Interventions par Jour de la Semaine">
                        <BarChart width={500} height={300} data={dataByDay}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="day" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="interventions" fill="#82ca9d" />
                        </BarChart>
                    </Card>
                </div>

                <div className="w-full">
                    <Card title="Fréquence des Interventions par Heure">
                        <BarChart width={1200} height={300} data={dataByHour}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="hour" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="interventions" fill="#8884d8" />
                        </BarChart>
                    </Card>
                </div>

            </div>
        </>
    );
}

export default InterventionStat