import { Dropdown } from 'primereact/dropdown';
import CustomDropdown from './CustomDropdown';
import { DEBOUNCE_TIME } from '../../crud/list';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../services/urls';
import { AutoComplete } from 'primereact/autocomplete';


const CustomRelatedForeignKey = ({ resource, onChange, initialValue, multiple, ...othersProps }) => {

  const itemToOption = (item) => {
    if (!item) return
    return ({label: resource.getLabel(item), value: item.id})
  }

  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);
  const [loading, setLoading] = useState(false);

  const search = (event) => {
    // Timeout to emulate a network connection
    const handler = setTimeout(() => {
      fetchData(event.query.toLowerCase())
    }, DEBOUNCE_TIME);
    return () => clearTimeout(handler);
  }

  useEffect(() => {
    if (multiple && initialValue?.length) {
      let initData = initialValue.map(itemToOption)
      setSelectedValue(initData)
    } else if (!multiple) {
      let initData = itemToOption(initialValue)
      setSelectedValue(initData)
    }
    fetchData("")
  }, [])

  const fetchData = async (searchTerm) => {
    try {
      setLoading(true)
      const response = await axios.get(`${API_BASE_URL}/${resource.endpointPrefix}`, {
        params: {search: searchTerm}
      });

      setData(response.data.results.map(itemToOption));

      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  return (
    <AutoComplete
      {...othersProps}
      multiple={multiple}
      field="label"
      value={selectedValue}
      suggestions={data}
      completeMethod={search}
      onChange={(e) => {setSelectedValue(e.value); onChange(e.value)}}
      disabled={loading}
      dropdown
    />
  );
}

export default CustomRelatedForeignKey
