import UserMenuDropdown from './UserMenuDropdown';
import logo from '../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import UserMenuNotification from './UserMenuNotification';

const HorizontalNavbar = ({ showSidebarInMobile, setShowSidebarInMobile }) => {
    const { user } = useContext(UserContext);

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-40">
            <div className="flex flex-wrap justify-between items-center">
                <div className="flex justify-start items-center">
                    <button
                        data-drawer-target="drawer-navigation"
                        data-drawer-toggle="drawer-navigation"
                        aria-controls="drawer-navigation"
                        className="p-2 mr-2 md:hidden text-gray-600 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={() => setShowSidebarInMobile(!showSidebarInMobile)}
                    >
                        <Icon icon="pepicons-pop:menu" />
                        <span className="sr-only">Toggle sidebar</span>
                    </button>
                    <NavLink to="/app/personnel-medical" className="flex items-center justify-between mr-4">
                        <img className="w-8 sm:w-10 md:w-12" src={logo} alt="Logo SAMU BENIN" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white ml-2">SAMU BENIN</span>
                    </NavLink>
                </div>

                <div className="hidden md:flex items-center justify-center">
                    <h3 className="text-2xl leading-relaxed tracking-wide text-gray-900 dark:text-white"><span className="text-4xl font-bold text-secondary">C</span>entre de <span className="text-4xl font-bold text-secondary">R</span>éception et de <span className="text-4xl font-bold text-secondary">R</span>égulation des <span className="text-4xl font-bold text-secondary">A</span>ppels</h3>
                </div>

                <div className="flex items-center lg:order-2">
                    {/* <button type="button" data-dropdown-toggle="notification-dropdown" className="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    <a href="">
                        <Icon icon="mingcute:notification-line" className="text-base xl:text-lg p-2 bg-secondary text-white rounded" />
                        <span className="sr-only">Notifications</span>
                        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-lightGreen border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">0</div>
                    </a>
                </button> */}
                    <div className="hidden overflow-hidden z-40 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:divide-gray-600 dark:bg-gray-700" id="notification-dropdown">
                        <div className="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300">
                            Notifications
                        </div>
                    </div>

                    <UserMenuNotification roles={user.groups} />
                    <UserMenuDropdown email={user.email} first_name={user.first_name} last_name={user.last_name} />
                </div>
            </div>
        </nav>
    )
}

export default HorizontalNavbar