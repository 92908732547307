import React from 'react';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from '../../components/crud/constants.js';
import CrudList from '../../components/crud/list.js';


const rolesFr = {
  "admin": "Directeur général",
  "arm": "Assistant de régulation médicale",
  "company-director": "Directeur de société",
  "branch-director": "Directeur de branche",
  "chief-supervisor": "Surveillant",
  "branch-supervisor": "Superviseur de branche",
  "medical-regulator": "Medecin régulateur",
  "ambulance-chief": "Chef d'ambulance",
  "ambulance-member": "Membre d'équipe d'intervention",
  "ambulance-driver": "Chauffeur d'ambulance",
  "emergency-nurse": "Urgentiste",
  "rescuer": "Secouriste",
  "permanent-staff": "Personnel permanant",
};

const MedicalMemberList = () => {

  return (
    <CrudList
      type={CRUD_VIEW_TYPE.CARD}
      resource={{
        endpointPrefix: "users",
        label: "membre du personnel medical",
        labelPlural: "Personnel medical",
        pronoun: "un",
        pronounChange: "ce",
        icon: "mdi:user",
        getTitle: (item) => item.identifier,
        imageField: null
      }}
      fields={[
        {
          name: "username",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom d'utilisateur",
          labelPlural: "",
        },
        {
          name: "last_name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Nom",
          labelPlural: "",
        },
        {
          name: "first_name",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Prénoms",
          labelPlural: "Prénoms",
        },
        {
          name: "email",
          type: CRUD_FIELD_TYPE.STRING,
          required: true,
          label: "Email",
          labelPlural: "Prénoms",
        },
        {
          name: "groups",
          type: CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY,
          relatedResource: {
            endpointPrefix: "groups",
            getLabel: (group) => rolesFr[group.name]
          },
          required: true,
          label: "Rôle",
          labelPlural: "Rôles",
        },
        {
          name: "company",
          type: CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY,
          relatedResource: {
            endpointPrefix: "companies",
            getLabel: (company) => company.name
          },
          required: true,
          label: "Antenne",
          labelPlural: "Antennes",
        },
      ]}
      actions={{ create: true, update: true, delete: true }}
      defaultPageSize={8}
    />
  )
}

export default MedicalMemberList;
