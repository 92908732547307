import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const Dictaphone = ({ fieldName, onChange = () => { } }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening
  } = useSpeechRecognition();
  const [onSpeechMode, setOnSpeechMode] = useState(false)

  useEffect(() => {
    if (!onSpeechMode) return

    if (transcript && transcript.match(/tout effacer/g)?.length) {
      resetTranscript()
    }
    else {
      onChange(transcript)
    }
  }, [transcript])

  useEffect(() => {
    resetTranscript()
  }, [fieldName])

  if (!browserSupportsSpeechRecognition) {
    return
  }

  if (browserSupportsContinuousListening) {
    SpeechRecognition.startListening({ continuous: true, language: 'fr-FR' })
  } else {
    // Fallback behaviour
  }

  return (
    <div
      className='flex items-center p-1'
      style={{ border: `2px solid ${onSpeechMode ? "#0000BB" : "gray"}`, borderRadius: "50%" }}>
      <Icon
        onClick={() => setOnSpeechMode(!onSpeechMode)}
        icon={`mdi:microphone${onSpeechMode ? "" : "-off"}`}
        className={`
          text-4xl transition duration-75 menu-icon group-hover:text-gray-900 cursor-pointer
          ${onSpeechMode ? "text-[#0000BB]" : "text-gray-500"}
        `}
      />
    </div>
  );
};
export default Dictaphone;
