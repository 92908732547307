import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CRUD_FIELD_TYPE, CRUD_VIEW_TYPE } from './constants.js';
import CrudListViewTable from './listViewTable.js';
import CrudListViewCard from './listViewCard.js';
import { Tag } from 'primereact/tag';


const CARD_EXCERPT_MAX_LENGTH = 200


const CrudListView = (props) => {

  const actionsGetters = useMemo(() => {
    let _actions = []
    if (props.actions.update) {
      _actions.push({
        name: "update",
        icon: 'eva:edit-2-fill',
        label: "Modifier",
        onClick: (item) =>  () => { props.setSelectedItem(item); props.setShowFormModal(true) }
      })
    }
    if (props.actions.delete) {
      _actions.push({
        name: "delete",
        icon: 'trash-2-outline',
        label: "Supprimer",
        onClick: (item) => () => { props.setSelectedItem(item); props.setShowDeleteConfirmation(true) }
      })
    }
    return _actions
  }, [])

  if (props.type === CRUD_VIEW_TYPE.TABLE) return (
    <CrudListViewTable {...props} actionsGetters={actionsGetters} />
  )

  if (props.type === CRUD_VIEW_TYPE.CARD) return (
    <CrudListViewCard {...props} actionsGetters={actionsGetters}/>
  )
  return null
};

export const getItemFieldFormatter = (field) => {
  if ([CRUD_FIELD_TYPE.ENUM, CRUD_FIELD_TYPE.ENUM_LIST].includes(field.type)) {
    const valueMapSeverity = field.choices.reduce((acc, choice) => {
      acc[choice.value] = choice.severity
      return acc
    }, {})
    const valueMapLabel = field.choices.reduce((acc, choice) => {
      acc[choice.value] = choice.label
      return acc
    }, {})

    return (item) => {
      if (!item[field.name]?.length) return null
      else if (field.type === CRUD_FIELD_TYPE.ENUM_LIST) {
        return item[field.name].map(value => (
          <Tag value={valueMapLabel[value]} severity={valueMapSeverity[value[field.name]]} className="mr-1"></Tag>
        ))
      }
      return <Tag value={valueMapLabel[item[field.name]]} severity={valueMapSeverity[item[field.name]]}></Tag>;
    }
  }

  if (field.type === CRUD_FIELD_TYPE.STRING) {
    return item => {
      if (item[field.name]?.length > CARD_EXCERPT_MAX_LENGTH) {
        return item[field.name].substr(0, CARD_EXCERPT_MAX_LENGTH) + "..."
      }
      return item[field.name]
    }
  }

  if (field.type === CRUD_FIELD_TYPE.RELATED_FOREIGN_KEY) {
    return item => <Tag value={field.relatedResource.getLabel(item[field.name])} className="mr-1" />
  }

  if (field.type === CRUD_FIELD_TYPE.RELATED_MANY_TO_MANY) {
    
    return item => {
      if (!item[field.name]?.length) return null

      return item[field.name].map(entry => (        
        <Tag value={field.relatedResource.getLabel(entry)} className="mr-1" />
      ))
    }
  }

  if (field.type === CRUD_FIELD_TYPE.BOOLEAN) {
    return item => item[field.name] ? (
      <Tag value={"Oui"} className="mr-1" severity="info"/>
    ) : (
      <Tag value={"Non"} className="mr-1" severity="warning"/>
    )
  }

  if (field.type === CRUD_FIELD_TYPE.DATE) {
    return item => (new Date(item[field.name])).toLocaleDateString("fr", { dateStyle: "full" })
      .replace(/./, c => c.toUpperCase()) // If you need a capital
      .replace(/,? /, ", "); 
  }

  return item => item[field.name]
}

export default CrudListView;
