import { Icon } from '@iconify/react';
import images from '../../assets/images/banner-bg'

const medical_quotes = [
    "La médecine est l'art de conserver la santé et éventuellement de la rétablir après l'avoir perdue.",
    "Chaque vie que vous sauvez est un succès indéniable.",
    "Votre compassion est une lumière dans les moments les plus sombres.",
    "Le savoir est la meilleure médecine.",
    "Votre engagement fait une différence chaque jour.",
    "La guérison commence par un cœur bienveillant.",
    "Prenez soin de vous pour mieux prendre soin des autres.",
    "Votre résilience est une source d'inspiration pour tous.",
    "L'écoute est la première étape de la guérison.",
    "Chaque patient est une histoire, écoutez-la attentivement.",
    "La science guérit, mais l'art de soigner réconforte.",
    "Un sourire est parfois le meilleur remède.",
    "Votre travail est un acte de foi en l'humanité.",
    "La patience est une vertu essentielle en médecine.",
    "La santé est le trésor le plus précieux, et vous en êtes les gardiens.",
    "Ne sous-estimez jamais l'impact d'un mot gentil.",
    "Vous êtes les héros silencieux de notre société.",
    "L'empathie est aussi puissante que les médicaments.",
    "Votre vocation est un phare dans la tempête."
];
const get_today_banner = () => {
    const today = new Date().getDate();
    return images[today % 7];
};

const get_today_quotes = () => {
    const today = new Date().getDate();
    return medical_quotes[today % 19];
}

const HelloBanner = ({ name }) => {
    const motivational_text = get_today_quotes();
    const bg_url = get_today_banner();

    return (
        <div className="relative mx-auto w-full group">
            <img className="h-52 w-full object-cover rounded-md" src={bg_url} alt="Banner random" />
            <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
            <div className="absolute inset-0 flex flex-col items-start justify-start pt-8 px-8">
                <h2 className="text-white text-lg md:text-xl font-bold flex gap-4 items-center">
                    Hello {name}
                    <Icon icon={'solar:hand-shake-bold'} className=" text-white text-4xl group-hover:animate-bounce"></Icon>
                </h2>
                <div className="mx-auto">
                    <div className="flex justify-start">
                        <Icon icon={'ri:double-quotes-l'} className="text-white text-xl lg:text-4xl flex-start"></Icon>
                    </div>
                    <p className="text-justify leading-6 lg:leading-8 text-white text-sm sm:text-base lg:text-lg xl:text-xl font-semibold px-2">{motivational_text}</p>
                    <div className="flex justify-end">
                        <Icon icon={'ri:double-quotes-r'} className="text-white text-xl lg:text-4xl flex-end"></Icon>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelloBanner