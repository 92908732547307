import { Icon } from '@iconify/react/dist/iconify.js';
import logo from '../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import { NavBarItems } from '../StaffNavbar';

const MobileStaffNavbar = () => {

    return (
        <nav className="bg-white shadow">
            <div className="py-4 px-6 w-full flex xl:hidden justify-between items-center bg-white fixed top-0 z-40 shadow">
                <div aria-label="logo" role="img" tabIndex="0" className="focus:outline-none w-24">
                    <div className="flex w-full items-center justify-between">
                        <NavLink to="/app/personnel-medical" className="flex items-center justify-between mr-10">
                            <img className="w-8 sm:w-10 md:w-12" src={logo} alt="Logo SAMU BENIN" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ml-2">SAMU BENIN</span>
                        </NavLink>
                        <button id="cross" aria-label="close menu" className="focus:outline-none focus:ring-2 rounded-md text-gray-800">
                            <Icon icon="gravity-ui:xmark" className="text-2xl" />
                        </button>
                    </div>
                </div>
                <div className="flex items-center">
                    <button id="menu" aria-label="open menu" className="focus:outline-none focus:ring-2 focus:ring-gray-600 rounded-md text-gray-800">
                        <Icon icon="gravity-ui:bars" className="xl:text-lg" />
                    </button>
                </div>
            </div>

            <div className="fixed w-full h-full transform -translate-x-full z-40 xl:hidden" id="mobile-nav">
                <div className="bg-gray-800 opacity-50 w-full h-full"></div>
                <div className="w-64 z-40 fixed overflow-y-auto top-0 bg-white shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out">
                    <NavBarItems />
                </div>
            </div>
        </nav>
    )
}

export default MobileStaffNavbar
